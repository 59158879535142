import React, { useCallback, useMemo } from 'react';
import {
  IconButton,
  ListItemIcon,
  MenuItem,
  styled,
  Tooltip,
} from '@mui/material';
import { StarOutlineRounded, StarRounded } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import useSnackbar from '../hooks/useSnackbar';
import { useAppSelector } from '../redux/store';
import {
  useCreatePreferencesMutation,
  useGetPreferencesQuery,
  usePatchPreferencesMutation,
} from '../redux/services/preferences/api';

interface Props {
  asListItem?: boolean;
  className?: string;
  id?: string;
}

const FavBtn = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiSvgIcon-root': {
    fontSize: '1.75rem',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.text.secondary
        : theme.palette.secondary.main,
  },
}));

const QuickAccessButton = (props: Props) => {
  const { className, asListItem, id: tid } = props;
  const location = useLocation();
  const { showSuccess } = useSnackbar();
  const { data: preferences, isError } = useGetPreferencesQuery();
  const isEdgeEnv = useAppSelector((state) => state.environment === 'edge');

  const [changePreference] = usePatchPreferencesMutation();
  const [createPreference] = useCreatePreferencesMutation();

  const path = useMemo(() => {
    const lastPathValue = location.pathname.split('/').at(-1);
    const ifProjectPage =
      lastPathValue === 'overview' ||
      lastPathValue === 'software' ||
      lastPathValue === 'systems';

    return ifProjectPage
      ? location.pathname.split('/').slice(0, -1).join('/')
      : location.pathname;
  }, [location.pathname]);

  const isExists = preferences?.preferences?.quickAccess
    ? preferences?.preferences?.quickAccess?.findIndex(
        (_id) => _id === path,
      ) !== -1
    : false;

  const handleModify = () => {
    if (isError || !preferences?.preferences) {
      createPreference({
        preferences: {
          quickAccess: [path],
        },
      });
      return;
    }
    if (isExists) {
      changePreference({
        preferences: {
          quickAccess: [
            ...preferences.preferences.quickAccess.filter(
              (item) => item !== path,
            ),
          ],
        },
      });
    } else {
      changePreference({
        preferences: {
          quickAccess: preferences.preferences.quickAccess
            ? [...preferences.preferences.quickAccess, path]
            : [path],
        },
      });
    }
  };

  const handleSetFav = useCallback(() => {
    showSuccess(
      isExists ? 'Removed from quick access' : 'Added to quick access',
    );
    handleModify();
  }, [isExists]);

  if (isEdgeEnv) return null;

  return (
    <>
      {asListItem ? (
        <MenuItem id={tid} onClick={handleSetFav} className={className}>
          <ListItemIcon>
            {isExists ? (
              <StarRounded sx={{ mr: 1, ml: '-2px' }} />
            ) : (
              <StarOutlineRounded sx={{ mr: 1, ml: '-2px' }} />
            )}
          </ListItemIcon>
          {isExists ? 'Remove from quick access' : 'Add to quick access'}
        </MenuItem>
      ) : (
        <Tooltip
          title={isExists ? 'Remove from quick access' : 'Add to quick access'}
          PopperProps={{
            container: () => document.querySelector('.fullscreen'),
          }}
        >
          <FavBtn className={className} onClick={handleSetFav} id={tid}>
            {isExists ? <StarRounded /> : <StarOutlineRounded />}
          </FavBtn>
        </Tooltip>
      )}
    </>
  );
};

QuickAccessButton.defaultProps = {
  className: '',
  id: '',
  asListItem: false,
};

export default QuickAccessButton;
