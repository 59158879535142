import React, { useMemo } from 'react';
import cn from 'classnames';
import { DetailWrap, Status } from './StatusStyles';
import { SYSTEMS_STATUSES } from '../../../constants';
import { useGetSystemsQuery } from '../../../redux/services/systems/api';
import { useAppSelector } from '../../../redux/store';
import { IAppState } from '../../../typescript/interfaces/appstate.interface';

const defaultProps = {
  showFull: false,
  fullscreen: false,
  style: undefined,
};

const SystemMachineStatusTextSubscription = ({
  systemID,
  style,
  showFull,
  fullscreen,
}: {
  systemID: string;
  style?: any;
  showFull?: boolean;
  fullscreen?: boolean;
}) => {
  const { data: system } = useGetSystemsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      data: data?.find((s) => s.id === systemID),
    }),
  });

  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );

  const title = useMemo(() => {
    if (system) {
      if (isEdgeEnv) return system?.machineState;
      if (!system?.machineState && !isEdgeEnv) return 'Loading';
      return system?.machineState;
    }

    return 'Unpaired';
  }, [system]);

  const className = useMemo(
    () =>
      SYSTEMS_STATUSES.map((status) => status.toLowerCase()).indexOf(
        title?.toLowerCase().replace(/ /gm, '_'),
      ) > -1
        ? title.toLowerCase().replace(/ /gm, '_')
        : 'running',
    [title],
  );

  if (isEdgeEnv) return null;

  return (
    <>
      {fullscreen ? (
        <DetailWrap sx={{ overflow: showFull ? 'inherit' : 'hidden' }}>
          <span>{showFull ? title : title.slice(0, 22)}</span>
          <Status
            sx={{
              padding: 0,
              width: 8,
              height: 8,
              backgroundColor: 'rgba(112, 112, 112, 0.15)',
            }}
            id={`SystemStatus-Machine-${systemID}`}
            className={className}
          />
        </DetailWrap>
      ) : (
        <Status
          className={cn(className, !showFull && 'trimmed')}
          style={style}
          id={`SystemStatus-Machine-${systemID}`}
        >
          {title}
        </Status>
      )}
    </>
  );
};

SystemMachineStatusTextSubscription.defaultProps = defaultProps;

export default SystemMachineStatusTextSubscription;
