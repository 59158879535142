import React, { ChangeEvent, useState } from 'react';
import cn from 'classnames';
import {
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Tooltip,
} from '@mui/material';
import { CloseRounded, SearchRounded } from '@mui/icons-material';
import { useAppSelector } from '../redux/store';
import { IAppState } from '../typescript/interfaces/appstate.interface';

interface Props {
  onSearch?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value?: string;
  id?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onFocus?: (ev: any) => void;
  onBlur?: (ev: any) => void;
  onCancel?: (ev: any) => void;
  sx?: any;
  defaultExpanded?: boolean;
}

const InputField = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: 0,
  '& .MuiOutlinedInput-input': {
    fontSize: '1.25rem',
    fontWeight: 600,
    height: 'auto',
    display: 'flex',
    padding: '2px 0',
    minHeight: '16px',
    color: theme.palette.text.primary,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiInputAdornment-root': {
    color: theme.palette.text.secondary,
    '& svg': {
      fontSize: '1.5rem',
    },
  },
}));

const ToggleBtn = styled(IconButton)({
  width: '2.5rem',
  height: '2.5rem',
});

function Search(props: Props) {
  const {
    className,
    id,
    placeholder,
    value,
    onSearch,
    autoFocus,
    onFocus,
    onBlur,
    onCancel,
    defaultExpanded,
    sx,
  } = props;
  const [searchValue, setSearch] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <InputField
      sx={sx}
      className={cn(className)}
      id={id}
      placeholder={placeholder}
      value={value || searchValue}
      onChange={onSearch || handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      autoFocus={autoFocus}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRounded sx={{ color: 'text.disabled' }} className="icon" />
          </InputAdornment>
        ),
        endAdornment:
          !defaultExpanded || value ? (
            <InputAdornment position="end">
              <Tooltip title="Clear">
                <ToggleBtn id="SearchCanel-icon" onClick={onCancel}>
                  <CloseRounded className="icon" />
                </ToggleBtn>
              </Tooltip>
            </InputAdornment>
          ) : (
            <InputAdornment position="end">
              <ToggleBtn id="SearchCanel-icon_expanded" sx={{ opacity: 0 }}>
                <CloseRounded className="icon" />
              </ToggleBtn>
            </InputAdornment>
          ),
      }}
    />
  );
}

Search.defaultProps = {
  onSearch: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onCancel: () => {},
  className: '',
  id: '',
  value: '',
  autoFocus: false,
  defaultExpanded: false,
  placeholder: 'Search anything',
  sx: {},
};
export default Search;
