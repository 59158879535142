import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { deriveIDWithType, AggregateType } from 'pc-id';
import {
  extendedApi,
  useGetSystemByIdQuery,
  useGetSystemsQuery,
} from '../redux/services/systems/api';
import { useGetInstrumentsByAggregateQuery } from '../redux/services/instruments/api';
import { useGetAttributeByAggregateQuery } from '../redux/services/attributes';
import { apiBaseUrlV1 } from '../env';
import { useAppDispatch, useAppSelector } from '../redux/store';
import PhaseEventSourceComponent, {
  Message,
} from './PhaseEventSourceComponent';
import { IAppState } from '../typescript/interfaces/appstate.interface';
import { getPropertyName } from '../utils/helper';
import { SystemState } from '../redux/services/polling';
import { IComponent } from '../typescript/interfaces/component.interface';
import { IInstrument } from '../typescript/interfaces/instrument.interface';

interface Props {
  system: string;
}

interface SystemComponentState extends IComponent {
  instruments: IInstrument[];
  controlAttributes: string[];
}

const SystemComponentsSubscription = (props: Props): React.JSX.Element => {
  const { system } = props;

  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );
  const dispatch = useAppDispatch();
  const componentsStatesRef = useRef<SystemComponentState[]>();

  const { data: systemSelector } = useGetSystemsQuery(undefined, {
    selectFromResult: (res) => ({
      ...res,
      data: res?.data?.find(({ id }) => id === system),
    }),
  });
  const { data: systemDetails } = useGetSystemByIdQuery(system, {
    skip: !system,
  });
  const { data: instruments } = useGetInstrumentsByAggregateQuery(system);
  const { data: attributes } = useGetAttributeByAggregateQuery(system);

  const components = useMemo(
    () => systemDetails?.components.filter(({ name }) => name !== 'phase'),
    [systemDetails],
  );

  const componentsStatesObject: SystemComponentState[] | undefined =
    useMemo(() => {
      if (components === undefined) return undefined;
      if (instruments === undefined) return undefined;
      if (attributes === undefined) return undefined;

      const componentsWithInstrument = components.map((component) => ({
        ...component,
        instruments: instruments.filter(({ parentAggregate, name }) => {
          if (component?.visual?.inputs)
            return (
              parentAggregate === component.id &&
              Object.values(component?.visual?.inputs)?.find(
                (v) =>
                  name.indexOf(
                    getPropertyName(v as string, 'instrument') as string,
                  ) > -1,
              )
            );
          return parentAggregate === component.id;
        }),
      }));

      const componentsWithAttribute = componentsWithInstrument
        .filter((component) => component.instruments.length > 0)
        .map((component) => ({
          ...component,
          attributes: attributes.filter(({ parentAggregate, name }) => {
            if (component?.visual?.inputs)
              return (
                component.instruments.find(
                  ({ id }) => parentAggregate === id,
                ) &&
                Object.values(component?.visual?.inputs)?.find(
                  (v) => getPropertyName(v as string, 'attribute') === name,
                )
              );
            return parentAggregate === component.id;
          }),
        }));

      const componentsWithControlAttribute = componentsWithAttribute.map(
        (component) => {
          if (!component?.attributes) return component;
          return {
            ...component,
            controlAttributes: component?.attributes.map(({ id }) =>
              deriveIDWithType(id, AggregateType.CATR),
            ),
          };
        },
      );

      return componentsWithControlAttribute;
    }, [components, attributes, instruments]);

  const url = useMemo(() => {
    if (
      componentsStatesObject === undefined ||
      componentsStatesObject?.length === 0
    ) {
      return undefined;
    }

    return `${apiBaseUrlV1(
      'stream/v2',
    )}/attributes/streams/json?${componentsStatesObject
      .filter(({ controlAttributes }) => controlAttributes)
      .map(({ controlAttributes }) => controlAttributes.map((v) => `id=${v}`))
      .flat(2)
      .join('&')}&rangeIsLive=true&samplerEnabled=false`;
  }, [componentsStatesObject]);

  const handleMessage = useCallback(
    (message: Message) => {
      const componentStates = componentsStatesRef.current;
      const parsedMessage = JSON.parse(message.data);
      const { id: cAttrId } = parsedMessage;
      const component = componentStates.find(
        (c) => c.controlAttributes.indexOf(cAttrId) > -1,
      );

      if (!component) return;
      const state = JSON.parse(JSON.parse(parsedMessage.state));
      const componentValue = component.value?.[cAttrId];

      if (JSON.stringify(state) !== JSON.stringify(componentValue)) {
        dispatch(
          extendedApi.util.updateQueryData(
            'getSystemById',
            system,
            (systemDraft) => {
              const comp = systemDraft?.components?.map((s) => {
                if (
                  s.id === component.id &&
                  JSON.stringify(s.value?.[cAttrId]) !==
                    JSON.stringify(state) &&
                  component.controlAttributes.indexOf(cAttrId) > -1
                ) {
                  return {
                    ...s,
                    value: { ...s.value, [cAttrId]: state },
                  };
                }
                return s;
              });
              return { ...systemDraft, components: comp };
            },
          ),
        );
      }
    },
    [dispatch, system],
  );

  useEffect(() => {
    if (!componentsStatesObject) return;
    componentsStatesRef.current = componentsStatesObject;
  }, [componentsStatesObject]);

  if (
    systemSelector?.machineState?.toUpperCase() !== SystemState.ONLINE &&
    !isEdgeEnv
  )
    return null;

  if (!url) return null;

  return (
    <PhaseEventSourceComponent
      handleStreamData={handleMessage}
      eventKeyType="/pc-domain/AttributeValueObject"
      urlProp={url}
    />
  );
};

export default SystemComponentsSubscription;
