import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  memo,
} from 'react';
import { useParams } from 'react-router';
import {
  Background,
  MiniMap,
  useNodesState,
  useEdgesState,
  useReactFlow,
  Node,
} from 'react-flow-renderer';
import { Box, useTheme } from '@mui/material';
import {
  convertFromDBComponents,
  convertFromDBConnectors,
} from '../../utils/DiagramUtils';
import ControlsButtons from './ControlButtons';
import { nodesTypes } from './nodeTypes';
import {
  ErrorIcon,
  ErrorText,
  ErrorWrap,
  LoaderWrap,
  ReactFlowStyled,
} from './styles';
import Loader from '../Loader';
import {
  useGetSystemByIdQuery,
  useGetSystemsQuery,
} from '../../redux/services/systems/api';
import { useGetProjectByIdQuery } from '../../redux/services/ensProjects/api';
import { useGetPortsQuery } from '../../redux/services/ports/api';
import { Context } from './CreateProjectDiagram/context';
import SystemComponentsSubscription from '../../hoc/SystemComponentsSubscription';
import CustomQEdge from './nodeTypes/library/utils/CustomQEdge';
import { ButtonStateProps } from '../../components/system/overview/RunButton';
import { colorToHex, hexToRGBA } from '../../utils/stringUtils';

interface Props {
  isEditable?: boolean;
  system: string | undefined;
  isFullscreen?: boolean;
  onFullscreenExit: any;
  onFullscreenEnter: any;
  withFullscreen?: boolean;
  withGrid?: boolean;
  onModalClose?: any;
  button?: any;
  setButton: Dispatch<SetStateAction<ButtonStateProps>>;
  componentsCustomUI: string[];
}

const DiagramComponent = (props: Props) => {
  const {
    isEditable,
    system,
    isFullscreen,
    onFullscreenExit,
    onFullscreenEnter,
    withFullscreen,
    withGrid,
    onModalClose,
    button,
    setButton,
    componentsCustomUI,
  } = props;
  const params = useParams<{ id: string }>();
  const theme = useTheme();

  const [showGrid, setShowGrid] = useState(false);
  const [componentsStreamState] = useState();
  const [currentNameTypes] = useState(true);
  const [interaction, setInteraction] = useState({
    draggable: false,
    zoomable: true,
  });
  const [isLoading, setIsLoading] = useState({});
  const [bgColor, setBgColor] = useState<string>();

  const { data: systems } = useGetSystemsQuery(undefined);
  const nodeTypes = useMemo(() => nodesTypes, []);
  const edgeTypes = useMemo(() => ({ custom: CustomQEdge }), []);

  const flowRef = useRef(null);

  useEffect(() => {
    setInteraction((interact) => ({ ...interact, draggable: false }));
  }, [false]);

  const onNodeMouseEnter = (_ev, val) => {
    if (val.id.endsWith('-dialog')) {
      setInteraction((interact) => ({
        ...interact,
        draggable: true,
        zoomable: false,
      }));
    } else {
      setInteraction((interact) => ({
        ...interact,
        draggable: false,
        zoomable: true,
      }));
    }
  };

  const onNodeMouseLeave = (_ev, val) => {
    setInteraction((interact) => ({
      ...interact,
      draggable: false,
      zoomable: true,
    }));
  };

  const toggleGrid = () => {
    setShowGrid(!showGrid);
  };

  const {
    data: currentSystemSelector,
    isLoading: isCurrentSystemLoading,
    error: currentSystemError,
  } = useGetSystemByIdQuery(system, {
    skip: !system || system.startsWith('PROJ'),
  });
  const {
    data: currentProject,
    isLoading: isCurrentProjectLoading,
    error: currentProjectError,
  } = useGetProjectByIdQuery(system, {
    skip: !system || system.startsWith('SYST'),
  });
  const { data: ports } = useGetPortsQuery(system, {
    skip: !system,
  });

  const currentSystem = useMemo(() => {
    if (!system) return undefined;
    return system?.startsWith('PROJ') ? currentProject : currentSystemSelector;
  }, [system, currentSystemSelector, currentProject]);

  const isSystemDataLoading = system?.startsWith('SYST')
    ? isCurrentSystemLoading
    : isCurrentProjectLoading;

  const { components, connectors } = useMemo(
    () => ({
      components: currentSystem?.components || [],
      connectors: currentSystem?.connectors || [],
    }),
    [currentSystem],
  );

  const isSoloSystem = useMemo(() => {
    if (
      system === currentSystemSelector?.id &&
      currentSystemSelector?.connectors
    ) {
      if (currentSystemSelector?.connectors.length === 1) return true;
    }
    if (system === currentProject?.id && currentProject?.connectors) {
      if (currentProject?.connectors.length === 1) return true;
    }
    return false;
  }, [system, currentSystemSelector, currentProject]);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const handleNodeLoading = (node: string): void => {
    setIsLoading((prev) => ({ ...prev, [node]: false }));
  };

  useEffect(() => {
    if (!currentSystem?.backgroundColor) return;
    if (theme.palette.mode === 'dark' && currentSystem?.backgroundColorDark) {
      const color = hexToRGBA(
        colorToHex(currentSystem.backgroundColorDark),
        100,
      );
      setBgColor(color);
      return;
    }
    const color = hexToRGBA(colorToHex(currentSystem.backgroundColor), 100);
    setBgColor(color);
  }, [
    currentSystem?.backgroundColor,
    currentSystem?.backgroundColorDark,
    theme?.palette?.mode,
  ]);

  useEffect(() => {
    if (
      params?.id &&
      !params?.id?.startsWith('PROJ') &&
      params?.id !== currentSystem?.id
    ) {
      return;
    }

    if (currentSystem?.components) {
      const local = currentSystem?.components;

      const dialogNodes = nodes.filter(({ id }) => id.endsWith('-dialog'));
      if (local.length > 0) {
        const newNodes = [
          ...convertFromDBComponents(
            local,
            currentSystem,
            componentsStreamState,
            currentNameTypes,
          ),
          ...dialogNodes,
        ];

        setIsLoading((prev) =>
          Object.assign(
            {},
            ...newNodes
              .filter(({ type }) => type === 'diagramComponent')
              .map(({ id }) =>
                // @ts-ignore
                !prev[id] ? { [id]: true } : { [id]: prev[id] },
              ),
          ),
        );
        setNodes(
          newNodes.map((n) => ({
            ...n,
            data: { ...n.data, handleNodeLoading },
          })),
        );
      }
    }
  }, [currentSystem?.id, components.length]);

  useEffect(
    () => () => {
      setNodes([]);
      setEdges([]);
      setIsLoading({});
    },
    [currentSystem?.id],
  );

  const currentStatus = useMemo(
    () => systems?.find(({ id }) => id === system)?.state,
    [systems],
  );

  useEffect(() => {
    if (connectors && nodes.length > 0 && ports?.length > 0) {
      const local = currentSystem.connectors;

      const running = currentStatus > 0;

      setEdges(() => {
        const connectorsNew = convertFromDBConnectors(
          local,
          ports,
          running,
        ).filter((connector) => {
          const foundIndexSource = nodes.findIndex(
            (component) => component.id === connector.source,
          );
          const foundIndexTarget = nodes.findIndex(
            (component) => component.id === connector.target,
          );

          return foundIndexSource !== -1 && foundIndexTarget !== -1;
        });

        return connectorsNew;
      });
    }
  }, [currentSystem, connectors, nodes, ports]);

  const reactFlowInstance = useReactFlow();

  const handleCloseDialogNode = (closeId: string) => {
    setNodes((nds) => nds.filter(({ id }) => id !== closeId));
    setInteraction((interact) => ({
      ...interact,
      draggable: false,
      zoomable: true,
    }));
  };

  const handleDialogNode = useCallback(
    (
      id: string,
      el:
        | (Node<any> & { handleCloseDialogNode: (id: string) => void })
        | undefined,
      event?: React.MouseEvent<Element, MouseEvent>,
    ) => {
      if (
        !componentsCustomUI?.includes(el?.data?.label?.props?.name) &&
        id !== ''
      )
        return;

      if (!id && !el) {
        setNodes((nds) => nds.filter(({ type }) => type !== 'dialogCustom'));
        return;
      }

      if (id && !el.position) {
        return;
      }

      const isDialogNotExists =
        nodes.filter((dialog) => dialog.id === `${id}-dialog`).length === 0;

      if (isDialogNotExists) {
        let {
          position: { x, y },
        } = el;

        if (Number(x) !== x) x = event?.clientX;
        if (Number(y) !== y) y = event?.clientY;

        const dialogNode = {
          id: `${id}-dialog`,
          label: 'dialog',
          type: 'dialogCustom',
          data: {
            ...el,
            data: { label: undefined, systemId: currentSystem.id },
          },
          position: {
            x:
              x +
              (el?.width + 50 ||
                el?.data?.renderProperties?.size?.width + 50 ||
                200),
            y,
          },
        };

        setNodes((nds) => [...nds, dialogNode]);
      }
    },
    [componentsCustomUI, nodes],
  );

  const reverseSearch = (node: any) => {
    if (node.nodeName === 'BODY') return undefined;

    if (!node.parentNode?.dataset?.id) {
      return reverseSearch(node.parentNode);
    }
    return node.parentNode?.dataset?.id;
  };

  const handleClickListner = useCallback(
    (ev) => {
      // catch dataid in parents
      const componentClicked =
        ev?.path?.filter(({ dataset }) => dataset?.id)[0] ||
        reverseSearch(ev.target);

      if (!componentClicked) return undefined;

      let componentSelected;
      if (!nodes.length) {
        if (!currentSystem?.components) return undefined;
        componentSelected = currentSystem.components.filter(
          ({ id }) =>
            id === componentClicked?.dataset?.id || id === componentClicked,
        );
      } else {
        [componentSelected] = nodes.filter(
          ({ id }) =>
            id === componentClicked?.dataset?.id || id === componentClicked,
        );
      }

      try {
        if (componentClicked?.dataset?.id.indexOf('-dialog') === -1) {
          handleDialogNode(componentSelected.id, {
            ...componentSelected,
            handleCloseDialogNode,
          });
        }
      } catch {
        console.info('componentClicked.dataset not found');
      }
      try {
        if (componentClicked?.indexOf('-dialog') === -1) {
          handleDialogNode(componentSelected.id, {
            ...componentSelected,
            handleCloseDialogNode,
          });
        }
      } catch {
        console.info('componentClicked.indexOf not working');
      }

      return undefined;
    },
    [nodes, currentSystem],
  );

  useEffect(() => {
    if (currentSystem?.id === system && flowRef?.current) {
      flowRef?.current.addEventListener('click', handleClickListner);
      flowRef?.current.addEventListener('touchstart', handleClickListner);
    }
    return () => {
      flowRef?.current?.removeEventListener('click', handleClickListner);
      flowRef?.current?.removeEventListener('touchstart', handleClickListner);
    };
  }, [flowRef.current, nodes, currentSystem]);

  useEffect(() => {
    if (reactFlowInstance) {
      setTimeout(() => {
        reactFlowInstance.fitView({
          padding: isSoloSystem ? 0.6 : 0.3,
        });
      }, 50);
    }
  }, [isFullscreen, system, JSON.stringify(isLoading)]);

  const onNodeClick = (
    event: React.MouseEvent<Element, MouseEvent>,
    node: Node<any>,
  ) => {
    if (node.id.indexOf('-dialog') === -1) {
      const nodeData = nodes.filter(({ id }) => id === node.id)[0];
      handleDialogNode(
        node.id,
        {
          ...nodeData,
          handleCloseDialogNode,
        },
        event,
      );
    }
  };

  const onPaneClick = () => {
    handleDialogNode('', undefined);
  };

  const contextValue = useMemo(
    () => ({ setNodes, nodesDraggable: interaction.draggable }),
    [setNodes, interaction.draggable],
  );

  if (currentSystemError || currentProjectError) {
    return (
      <ErrorWrap>
        <ErrorIcon />
        <ErrorText>Failed to load system detail information</ErrorText>
      </ErrorWrap>
    );
  }

  if (isSystemDataLoading) {
    return (
      <LoaderWrap>
        <Loader />
      </LoaderWrap>
    );
  }

  return (
    <>
      {!Object.values(isLoading).every((v) => !v) && (
        <LoaderWrap>
          <Loader />
        </LoaderWrap>
      )}
      {system?.startsWith('SYST') && components.length > 0 && (
        <SystemComponentsSubscription system={system} />
      )}
      <Context.Provider value={contextValue}>
        <ReactFlowStyled
          style={{ background: bgColor }}
          ref={flowRef}
          nodes={nodes}
          edges={edges}
          nodesDraggable={interaction.draggable}
          nodesConnectable={interaction.draggable}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          snapGrid={[1, 1]}
          minZoom={0.1}
          defaultZoom={0.5}
          panOnDrag={!interaction.draggable}
          selectNodesOnDrag={interaction.draggable}
          onNodeClick={onNodeClick}
          onPaneClick={onPaneClick}
          onNodeMouseEnter={onNodeMouseEnter}
          onNodeMouseLeave={onNodeMouseLeave}
          zoomOnScroll={interaction.zoomable}
          // @ts-ignore
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitView
          fitViewOptions={{ padding: isSoloSystem ? 0.6 : 0.3 }}
        >
          <ControlsButtons
            toggleGrid={toggleGrid}
            showGrid={showGrid}
            isEditable={isEditable}
            withFullscreen={withFullscreen}
            isFullscreen={isFullscreen}
            withGrid={withGrid}
            onFullscreenEnter={onFullscreenEnter}
            onFullscreenExit={onFullscreenExit}
            reactFlowInstance={reactFlowInstance}
            currentNameTypes={currentNameTypes}
            // handleCurrentNamesTypes={handleCurrentNamesTypes}
            system={system}
            onModalClose={onModalClose}
            button={button}
            setButton={setButton}
          />
          <Box sx={{ display: { xs: 'none', sm: 'inline' } }}>
            <MiniMap
              nodeColor={(node) => {
                switch (node.type) {
                  case 'pixer':
                    return '#eef5fa';
                  case 'water':
                    return '#4C9FC8';
                  case 'bufferConcentrate':
                    return '#eef5fa';
                  default:
                    return '#eef5fa';
                }
              }}
              nodeStrokeWidth={1}
            />
          </Box>
          {showGrid && (
            <Background size={0.75} gap={16} color="background.default" />
          )}
        </ReactFlowStyled>
      </Context.Provider>
    </>
  );
};

DiagramComponent.defaultProps = {
  onModalClose: () => {},
  isEditable: false,
  isFullscreen: false,
  withFullscreen: false,
  withGrid: false,
  button: {},
};

export default memo(DiagramComponent);
