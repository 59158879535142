/* eslint-disable no-shadow */
import React from 'react';
import { Button } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { SystemIcon } from '../../icons';
import { Bold, EmptyBox, Light } from '../../common/styles/empty';
import SystemScanButton from './SystemScanButton';
import { useAppSelector } from '../../redux/store';
import { IAppState } from '../../typescript/interfaces/appstate.interface';

interface Props {
  onCreate?: any;
  title?: string;
  detail?: string;
  height?: string;
  isHiddenCreate?: boolean;
}
function SystemEmpty(props: Props) {
  const { onCreate, title, detail, height, isHiddenCreate } = props;
  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );

  return (
    <EmptyBox
      sx={{ height: height || 'calc(100% - 210px)', mt: '-50px', flex: 1 }}
    >
      <SystemIcon className="icon" />
      <Bold>{title || 'There are no active Systems'}</Bold>
      <Light>
        {detail || 'You can create a new system with the button below.'}
      </Light>
      {!isHiddenCreate && !isEdgeEnv && (
        <>
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            onClick={onCreate}
            startIcon={<AddRounded />}
            name="AddSystem"
            id="SystemList-Button_AddSystem"
          >
            Add system
          </Button>
          <SystemScanButton />
        </>
      )}
    </EmptyBox>
  );
}

SystemEmpty.defaultProps = {
  title: '',
  detail: '',
  height: '',
  onCreate: () => {},
  isHiddenCreate: false,
};

export default SystemEmpty;
