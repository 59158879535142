/* eslint-disable no-shadow */
import React from 'react';
import cn from 'classnames';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  alpha,
  styled,
} from '@mui/material';

interface Props {
  sx?: any;
  value: any;
  onChange: any;
  statuses: any;
  id?: string;
}

const Control = styled(FormControl)(({ theme }) => ({
  width: 'calc(50% - 0.375rem)',
  marginBottom: 0,
  marginLeft: '0.375rem',
  [theme.breakpoints.down('md')]: {
    flexGrow: 1,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
  },
}));

const Status = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    display: 'inline-flex',
    alignItems: 'center',
    textAlign: 'left',
    wordBreak: 'break-all',
    padding: '2px 6px',
    borderRadius: theme.shape.borderRadius,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: '0.9rem',
    border:
      theme.palette.mode === 'dark' ? `1px solid rgba(255, 255, 255, 0.2)` : 0,
    '&.running': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.online': {
      backgroundColor: theme.palette.success.main,
    },
    '&.idle': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.offline': {
      color: theme.palette.text.primary,
      backgroundColor: alpha(theme.palette.offline.main, 0.15),
    },
    '&.warning': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.error': {
      backgroundColor: theme.palette.error.main,
    },
    '&.all': {
      color: theme.palette.text.primary,
      textTransform: 'none',
      fontSize: '13px',
      border: 0,
    },
    '&.unknown': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.text.primary,
    },
    '&.loading': {
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.text.primary}`,
    },
    '&.connected': {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
      backgroundColor: alpha(theme.palette.success.main, 0.1),
      border: '1px solid',
    },
    '&.awaiting_ens': {
      borderColor:
        theme.palette.mode === 'dark' ? '#D784F8' : theme.palette.primary.main,
      color:
        theme.palette.mode === 'dark' ? '#D784F8' : theme.palette.primary.main,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'transparent'
          : alpha(theme.palette.primary.main, 0.1),
      border: '1px solid',
    },
    '&.unpaired': {
      color: theme.palette.text.primary,
      backgroundColor: alpha(theme.palette.offline.main, 0.15),
    },
    '&.pairing': {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    },
  },
}));

const Label = styled(InputLabel)(({ theme }) => ({
  transform: 'translate(10px, -9.5px) scale(1)',
  backgroundColor: theme.palette.background.default,
  padding: '0 4px',
  width: 'auto',
}));

const Option = styled(MenuItem)(({ theme }) => ({
  padding: '0 !important',
  display: 'flex',
  justifyContent: 'flex-start',
  '&.divider': {
    marginBottom: '40px',
    position: 'relative',
    '&:after': {
      content: '""',
      borderBottom: `1px solid ${theme.palette.divider}`,
      display: 'block',
      position: 'absolute',
      width: '100%',
      top: 36,
    },
    '&:before': {
      content: '"Control status"',
      display: 'block',
      position: 'absolute',
      width: '100%',
      top: 40,
      left: theme.spacing(1),
    },
    '&:first-of-type': {
      '&:before': {
        content: '"Machine status"',
      },
    },
  },
}));

function StatusFilter(props: Props) {
  const { value, onChange, statuses, sx, id } = props;

  return (
    <Control sx={sx} variant="outlined">
      <Label id="status-label">Status</Label>
      <Select
        labelId="status-label"
        id={id || 'SystemStatus-filter'}
        multiple
        value={value.map((v) => v.replace(/_/gm, ' '))}
        onChange={onChange}
        input={<OutlinedInput />}
        renderValue={(selected) =>
          selected.map((v) => v.replace(/_/gm, ' ')).join(', ') === 'All'
            ? 'All statuses'
            : selected.join(', ')
        }
      >
        {statuses.map((s) => {
          const statusClass = cn({
            all: s === 'All',
            online: s === 'Online',
            offline: s === 'Offline',
            running: s === 'Running',
            warning: s === 'Warning',
            error: s === 'Error',
            idle: s === 'Idle',
            unknown: s === 'Unknown',
            connected: s === 'Connected',
            awaiting_ens: s === 'Awaiting_ENS',
            unpaired: s === 'Unpaired',
            pairing: s === 'Pairing',
            loading: s === 'Loading',
          });
          return (
            <Option
              key={s}
              value={s.replace(/_/gm, ' ')}
              className={s === 'All' || s === 'Online' ? 'divider' : ''}
            >
              <Checkbox
                checked={
                  value
                    .map((v) => v.replace(/_/gm, ' '))
                    .indexOf(s.replace(/_/gm, ' ')) > -1
                }
              />
              <Status
                classes={{ primary: statusClass }}
                primary={s === 'All' ? 'All statuses' : s.replace(/_/gm, ' ')}
                primaryTypographyProps={{
                  id: `StatusFilter-${statusClass}`,
                }}
              />
            </Option>
          );
        })}
      </Select>
    </Control>
  );
}

StatusFilter.defaultProps = {
  sx: {},
  id: '',
};

export default StatusFilter;
