/* eslint-disable @typescript-eslint/no-explicit-any */
// no-check

import { RouterChildContext } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { environment as env } from '../env';
import { REDIRECT_URI, passwordSettingsRules } from '../constants';
import { IPasswordSettings } from '../redux/services/users/types';
import { logoutStorage } from './oidcConfig';

interface CheckButtonProps {
  machineStatus: string;
  subscriptionState: number;
  environment: string;
  environmentState: boolean;
  remoteInputStatus: boolean;
}

const systemIsOfflineAndNoSubscription = (
  machineStatus: string,
  subscriptionState: number,
) =>
  machineStatus?.toLowerCase() !== 'online' && subscriptionState === undefined;

const systemIsOfflineAndSubscriptionIdle = (
  machineStatus: string,
  subscriptionState: number,
) => machineStatus?.toLowerCase() !== 'online' && subscriptionState === 0;

const systemIsOfflineAndSubscriptionNoIdle = (
  machineStatus: string,
  subscriptionState: number,
) =>
  machineStatus?.toLowerCase() !== 'online' &&
  subscriptionState !== 0 &&
  subscriptionState !== undefined;

const systemIsOnlineAndSubscriptionNoIdle = (
  machineStatus: string,
  subscriptionState: number,
) =>
  machineStatus?.toLowerCase() === 'online' && subscriptionState === undefined;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const checkButton = (props: CheckButtonProps) => {
  const {
    machineStatus,
    subscriptionState,
    environment,
    environmentState,
    remoteInputStatus,
  } = props;

  let button = {
    disabled: true,
    disabledReason: 'Loading...',
    running: false,
  };

  // system is offline and no subscription data
  if (systemIsOfflineAndSubscriptionIdle(machineStatus, subscriptionState)) {
    console.log(
      'systemIsOfflineAndSubscriptionIdle',
      machineStatus,
      subscriptionState,
    );
    button = {
      disabled: true,
      disabledReason: 'System is offline and no subscription data',
      running: false,
    };
  }

  // system is offline and there is subscription data
  if (systemIsOfflineAndNoSubscription(machineStatus, subscriptionState)) {
    console.log(
      'systemIsOfflineAndNoSubscription',
      machineStatus,
      subscriptionState,
    );
    button = {
      disabled: true,
      disabledReason: 'System is offline',
      running: false,
    };
  }

  // system is offline and subscription returns not idle
  if (systemIsOfflineAndSubscriptionNoIdle(machineStatus, subscriptionState)) {
    console.log(
      'systemIsOfflineAndSubscriptionNoIdle',
      machineStatus,
      subscriptionState,
    );
    button = {
      disabled: true,
      disabledReason: 'System is offline and subscription is not idle',
      running: false,
    };
  }

  // system is machineState and subscription returns not idle
  if (systemIsOnlineAndSubscriptionNoIdle(machineStatus, subscriptionState)) {
    console.log(
      'systemIsOnlineAndSubscriptionNoIdle',
      machineStatus,
      subscriptionState,
    );
    button = {
      disabled: true,
      disabledReason: 'Subscription is not idle',
      running: false,
    };
  }

  // system is machineState and subscription returns idle
  if (machineStatus?.toLowerCase() === 'online' && subscriptionState === 0) {
    console.log(
      'machineStatus?.toLowerCase() === online && subscriptionState === 0',
      machineStatus?.toLowerCase() === 'online',
      subscriptionState === 0,
    );
    button = {
      disabled: false,
      disabledReason: undefined,
      running: false,
    };
  }

  // system is machineState and subscription returns not idle
  if (
    machineStatus?.toLowerCase() === 'online' &&
    subscriptionState !== 0 &&
    subscriptionState !== undefined
  ) {
    console.log(
      'machineStatus?.toLowerCase() === online && subscriptionState !== 0 && subscriptionState !== undefined',
      machineStatus?.toLowerCase() === 'online',
      subscriptionState !== 0,
      subscriptionState !== undefined,
    );
    button = {
      disabled: false,
      disabledReason: undefined,
      running: true,
    };
  }

  // edge system and subscription unknown
  if (environment === 'edge' && subscriptionState === undefined) {
    console.log(
      'environment === edge && subscriptionState === undefined',
      environment === 'edge',
      subscriptionState === undefined,
    );
    button = {
      disabled: true,
      disabledReason: 'Subscription data is not available',
      running: false,
    };
  }

  // edge system and subscription returns idle
  if (environment === 'edge' && subscriptionState === 0) {
    console.log(
      'environment === edge && subscriptionState === 0',
      environment === 'edge',
      subscriptionState === 0,
    );
    button = {
      disabled: false,
      disabledReason: undefined,
      running: false,
    };
  }

  // edge system and subscription returns not idle
  if (
    environment === 'edge' &&
    subscriptionState !== 0 &&
    subscriptionState !== undefined
  ) {
    console.log(
      'environment === edge && subscriptionState !== 0 && subscriptionState !== undefined',
      environment === 'edge',
      subscriptionState !== 0,
      subscriptionState !== undefined,
    );
    button = {
      disabled: false,
      disabledReason: undefined,
      running: true,
    };
  }

  if (environmentState === false) {
    console.log('environmentState === false', environmentState === false);
    button = {
      ...button,
      disabled: true,
      disabledReason: 'Environment is offline',
    };
  }

  if (!remoteInputStatus && environment !== 'edge') {
    console.log(
      '!remoteInputStatus && environment !== edge',
      !remoteInputStatus,
      environment !== 'edge',
    );
    button = {
      ...button,
      disabled: true,
      disabledReason: 'Remote input is disabled',
    };
  }

  return button;
};

type Order = 'asc' | 'desc';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const getReturnPath = () => sessionStorage.getItem(REDIRECT_URI);

export const setReturnPath = () => {
  const { pathname, search } = window.location;
  sessionStorage.setItem(REDIRECT_URI, `${pathname}${search}`);
};

/**
 * Suffixes of request paths that should not be redirected from in case of 401
 * (essentially public endpoints).
 */
const redirectIgnorelist = [
  '/kratos/sessions/whoami',
  '/api/atreus/v1/me',
  '/ubiety/v1/env',
  'xmlns',
];

function shouldBeRedirected(requestUrl: string, responseStatus: number) {
  if (
    responseStatus !== 403 ||
    redirectIgnorelist.some((ignoredPart) => requestUrl.includes(ignoredPart))
  ) {
    return false;
  }
  return false;
}

export const checkCookiesValidity = ({
  propertyToCheck,
}: {
  propertyToCheck: string;
}) => document.cookie.split('; ').some((v) => v.indexOf(propertyToCheck) > -1);

interface TokensValue {
  token: string | undefined;
  expiresAt?: number;
}

export const setCookies = ({
  cookieName,
  values,
}: {
  cookieName: string;
  values: TokensValue;
}) =>
  (document.cookie = `${cookieName}=${values?.token || ''};${
    values?.expiresAt
      ? `Expires=${new Date(values.expiresAt * 1000).toUTCString()}`
      : ''
  };SameSite=None;Secure;domain=${window.location.host};path=/`);

export const authExpiredRedirectIfNeeded = (
  requestUrl: string,
  responseStatus: number | string,
) => {
  if (env() !== 'edge') return;
  if (localStorage.getItem(logoutStorage)) return;
  if (typeof responseStatus === 'number') {
    if (shouldBeRedirected(requestUrl, responseStatus)) {
      setReturnPath();
      window.location.href = '/?authExpired=true';
    }
  }
};

/**
 * If return path is present in session storage, then removes it and either:
 * - if no router history given, replaces the current window location with the path
 * - otherwise pushes the return path via the router history
 *
 * This behavior is taken from the withAuth HoC cloud auth flow
 * in which quite a bit of dancing around was happening with navigation
 * and it probably could be cleaned up to be more straightforward.
 *
 * @param history optional React Router history object
 */
export const redirectToReturnPath = (
  history?: RouterChildContext['router']['history'],
) => {
  const returnPath = sessionStorage.getItem(REDIRECT_URI);
  if (returnPath) {
    sessionStorage.removeItem(REDIRECT_URI);
    if (history) {
      history.push(returnPath);
    } else {
      window.location.replace(returnPath);
    }
  }
};

export const a11yProps = (
  index: string,
): {
  id: string;
  'aria-controls': string;
  value: string;
} => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
  value: index,
});

export const parseDateFormatString = (date: Date | number): string => {
  try {
    return new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'short',
      timeStyle: 'medium',
    }).format(date);
  } catch (_e) {
    return '';
  }
};

interface CustomQueryError {
  detail?: string | { msg?: string };
  data?:
    | {
        detail: string[] | { msg?: string };
      }
    | string;
  message?: string;
  status?: number | string;
}
export const defaultErrorMessage = 'Something went wrong';

const getStringErrorMessage = (error?: any) =>
  typeof error === 'string' ? error : defaultErrorMessage;

export const formatErrorMessage = (
  error: FetchBaseQueryError | SerializedError | CustomQueryError,
) => {
  if (!error) return null;
  if ('data' in error) {
    if (!error?.data) return getStringErrorMessage(error);
    if (typeof error.data !== 'object')
      return getStringErrorMessage(error.data);
    if ('detail' in error.data) {
      if (!Array.isArray(error?.data?.detail))
        return getStringErrorMessage(error.data.detail);
      if ('msg' in error.data.detail)
        return getStringErrorMessage(error.data.detail.msg);
      return error?.data?.detail?.map((v) => `${v?.msg}\n`).join('\n');
    }
    return getStringErrorMessage();
  }
  if ('detail' in error) {
    if (typeof error.detail === 'string')
      return getStringErrorMessage(error.detail);
    if ('msg' in error.detail) return getStringErrorMessage(error.detail.msg);
    return getStringErrorMessage(error.detail);
  }
  if ('message' in error) return getStringErrorMessage(error.message);
  return getStringErrorMessage();
};

interface KratosResponseCustomError {
  message?: string;
  code?: number;
  reason?: string;
  response?: { status?: number };
}
export const getErrorStatus = (
  err: KratosResponseCustomError | FetchBaseQueryError | SerializedError,
  fallback?: number,
): number | undefined => {
  if ('code' in err) return +err?.code;
  if ('status' in err) return +err?.status;
  if ('response' in err) return +err?.response?.status;
  return fallback || undefined;
};

export const parseErrorMessage = (
  err: KratosResponseCustomError | FetchBaseQueryError | SerializedError,
  fallback: string,
): string => {
  if ('message' in err) return err?.message;
  if ('reason' in err) return err?.reason;
  return fallback;
};

export const compareDatesInSorting = (d1: string | Date, d2: string | Date) => {
  const date1 = new Date(d1)?.getTime();
  const date2 = new Date(d2)?.getTime();
  if (date1 > date2) return 1;
  if (date1 < date2) return -1;
  return 0;
};

export const compareStringsInSorting = (str1: string, str2: string) => {
  const formatedStr1 = str1.toLocaleLowerCase();
  const formatedStr2 = str2.toLocaleLowerCase();
  if (formatedStr1 > formatedStr2) return 1;
  if (formatedStr1 < formatedStr2) return -1;
  return 0;
};

interface UtilityTokens {
  accessToken?: string;
  idToken?: string;
  expiresAt?: number;
}

export const setTokensUtil = (tokens: UtilityTokens) => {
  if (tokens?.accessToken) {
    localStorage.setItem('access_token', tokens.accessToken);
    setCookies({
      cookieName: 'access_token',
      values: {
        expiresAt: tokens?.expiresAt,
        token: tokens?.accessToken,
      },
    });
  }

  if (tokens?.idToken) {
    localStorage.setItem('id_token_hint', tokens.idToken);
  }
};

export const getPasswordPatternCheck = (
  password: string,
  settings: IPasswordSettings,
) =>
  passwordSettingsRules
    .filter(({ key }) => Boolean(settings[key]))
    .map(({ key, message, pattern }) => {
      let isMatched = false;
      let rule = '';
      if (typeof pattern !== 'function') {
        isMatched = pattern.test(password);
      } else {
        const regex = new RegExp(pattern(settings[key]));
        isMatched = password ? regex.test(password) : false;
      }

      if (typeof message !== 'function') {
        rule = `Password should ${message}`;
      } else {
        rule = `Password should ${message(settings[key])}`;
      }
      return { rule, isMatched };
    });

export const splitTimeByIntervals = (dates: Date[]): Date[][] => {
  const [start, stop] = dates;
  const startNum = start.getTime();
  const stopNum = stop.getTime();

  const resultingArray = [];

  let interim = startNum + 5 * 60 * 1000;
  let prevInterim = startNum;
  while (interim < stopNum) {
    resultingArray.push([prevInterim, interim]);
    prevInterim = interim;
    interim += 5 * 60 * 1000;
    if (interim >= stopNum) {
      interim = stopNum;
      resultingArray.push([prevInterim, interim]);
    }
  }
  if (resultingArray.length === 0) resultingArray.push([startNum, stopNum]);

  return resultingArray;
};

export const getPropertyName = (
  inputString: string,
  entityName: 'type' | 'instrument' | 'attribute' | 'attributeValue',
): string | string[] => {
  const stringArray = inputString.split('.');

  if (entityName === 'type') return stringArray?.[1];
  if (entityName === 'instrument') return stringArray?.[2];
  if (entityName === 'attribute') return stringArray?.[3];
  if (entityName === 'attributeValue') return stringArray?.[4];
  return stringArray;
};

export const encodeURL = (str: string) => encodeURIComponent(btoa(str));
export const decodeURL = (str: string) => atob(decodeURIComponent(str));

export const sortVersions = (versions: string[] | undefined) => {
  if (!versions) return undefined;

  const sorted = versions.sort((originalA, originalB) => {
    const a = originalA
      .split('.')
      .map((v, idx) => (idx < 3 ? +v.replace(/\D/g, '') : v));
    const b = originalB
      .split('.')
      .map((v, idx) => (idx < 3 ? +v.replace(/\D/g, '') : v));

    if (a[0] > b[0]) return -1;
    if (a[0] < b[0]) return 1;

    if (a[0] === b[0]) {
      if (a[1] > b[1]) return -1;
      if (a[1] < b[1]) return 1;
      if (a[1] === b[1]) {
        if (a[2] > b[2]) return -1;
        if (a[2] < b[2]) return 1;
        if (a[2] === b[2]) {
          if (a?.[3] > b?.[3] || b?.[3] === undefined) return -1;
          if (a?.[3] < b?.[3] || a?.[3] === undefined) return 1;
        }
      }
    }
    return 1;
  });
  return sorted;
};
