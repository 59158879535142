import {
  Uri,
  editor,
  Range,
  IPosition,
} from 'monaco-editor/esm/vs/editor/editor.api';
import {
  ActionsType,
  EditorActions,
} from '../typescript/interfaces/editor.interface';

export interface IValidationDetails {
  loc: string[];
  msg: string;
  type: string;
}

export interface IDataValidate {
  data: {
    detail: IValidationDetails[];
  };
}

export const getPositionInModel = (
  yamlInstance: editor.ICodeEditor,
  search: string,
): editor.FindMatch | undefined => {
  if (!yamlInstance) return undefined;

  const position = yamlInstance
    ?.getModel()
    ?.findMatches(search, true, false, true, null, false)?.[0];

  return position;
};

export const createBlob = (str: string, type?: string): Blob =>
  new Blob([str], { type: type || 'text/plain' });
export const createFile = (data: Blob, fileName: string, type: string): File =>
  new File([data], fileName, {
    type,
  });

export const removeAllDecorations = (
  yamlInstance: editor.ICodeEditor,
): void => {
  if (!yamlInstance) return;
  const allDecorations = yamlInstance.getModel().getAllDecorations();
  yamlInstance.removeDecorations(allDecorations.map((dec) => dec.id));
};

const findAllPositions = (
  locs: string[] | string,
  text: editor.ITextModel,
): editor.FindMatch[][] => {
  if (typeof locs !== 'string')
    return locs.map((l) => findAllPositions(l, text)).flat();
  const pos = text?.findMatches(locs, true, false, false, null, true);
  return [pos];
};

const findPositivePosition = (positions: editor.FindMatch[][]) => {
  const res = [];
  positions.forEach((d, idx) => {
    if (idx === 0) {
      res.push(d?.[0]);
      return;
    }
    res.push(
      d.find(
        (innerPos) =>
          innerPos?.range?.startLineNumber >
          res?.[idx - 1]?.range?.startLineNumber,
      ),
    );
  });
  return res.filter((v) => v !== undefined);
};

const compareMarkers = (prev, errMarkers) => {
  if (JSON.stringify(prev) !== JSON.stringify(errMarkers)) {
    return errMarkers;
  }
  return prev;
};

const getPositivePositions = (positivePositions, msg) => {
  if (positivePositions[positivePositions.length - 1] === undefined) {
    return {
      position: {
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: 1,
        endColumn: 1000,
      },
      message: msg,
    };
  }
  return {
    position: {
      startLineNumber:
        positivePositions[positivePositions.length - 1].range.startLineNumber,
      startColumn:
        positivePositions[positivePositions.length - 1].range.startColumn,
      endLineNumber:
        positivePositions[positivePositions.length - 1].range.endLineNumber,
      endColumn:
        positivePositions[positivePositions.length - 1].range.endColumn,
    },
    message: msg,
  };
};

const filterPosition = (
  errorsPositionItem: editor.FindMatch,
  depth: number,
  maxDepth: number,
  errPositions: editor.FindMatch[],
): editor.FindMatch[] => {
  if (depth === maxDepth) return [errorsPositionItem];
  return filterPosition(errPositions[depth], depth + 1, maxDepth, errPositions);
};

const getPosition = (
  errPositions: editor.FindMatch[],
  loc: string[],
  msg: string,
  firstMention: editor.FindMatch[],
) => {
  let errPosition = filterPosition(
    errPositions[0],
    0,
    errPositions.length - 1,
    errPositions,
  );

  if (errPosition.length === 0) {
    errPosition = filterPosition(
      errPositions[0],
      0,
      errPositions.length - 2,
      errPositions,
    );
  }
  if (errPosition.length > 0) {
    const err = errPosition[0];
    if (err)
      return {
        position: {
          startLineNumber: err.range.startLineNumber,
          startColumn: err.range.startColumn,
          endLineNumber: err.range.endLineNumber,
          endColumn: err.range.endColumn,
        },
        message: `${loc[loc.length - 1]}: ${msg}`,
      };
  }
  return {
    position: {
      startLineNumber: firstMention[0].range.startLineNumber,
      startColumn: 1,
      endLineNumber: firstMention[0].range.endLineNumber,
      endColumn: firstMention[0].range.endColumn,
    },
    message: `${loc[loc.length - 1]}: ${msg}`,
  };
};

export const getMarkers = (
  yamlInstance: editor.ICodeEditor,
  markerErrors: IValidationDetails[],
  setMarkers: React.Dispatch<React.SetStateAction<editor.IMarker[]>>,
  isValidErrors?: boolean,
): editor.IMarker[] => {
  if (
    typeof markerErrors !== 'string' &&
    Array.isArray(markerErrors) &&
    yamlInstance
  ) {
    const locations = markerErrors.map(({ loc, msg }) => {
      const text = yamlInstance.getModel();

      const errPositions = findAllPositions(loc, text);

      const positivePositions = findPositivePosition(errPositions);

      if (positivePositions.length > 0) {
        return getPositivePositions(positivePositions, msg);
      }

      const firstMention = errPositions?.[0]?.filter(
        ({ range }) => range.startColumn === 1,
      );

      if (loc[0] === '__root__' || loc[0] === 'body') {
        return {
          position: {
            startLineNumber: 1,
            startColumn: 1,
            endLineNumber: 1,
            endColumn: 1000,
          },
          message: msg,
        };
      }

      if (firstMention?.length > 0) {
        return getPosition(errPositions[0], loc, msg, firstMention);
      }
      return {
        position: {
          startLineNumber: 1,
          startColumn: 1,
          endLineNumber: 1,
          endColumn: 1000,
        },
        message: msg,
      };
    });

    if (locations.filter(({ position }) => position).length > 0) {
      const errMarkers = locations
        .filter(({ position }) => position)
        .map((m) => ({
          ...m.position,
          message: m.message,
          code: '0',
          severity: 8,
          source: 'YAML',
          owner: 'yaml',
          resource: '' as unknown as Uri,
        }));

      if (!isValidErrors) {
        setMarkers((prev) => compareMarkers(prev, errMarkers));
      }

      return errMarkers;
    }

    return [];
  }
  return [];
};

export const handleCleanDecorations = (yamlInstance: editor.ICodeEditor) => {
  if (yamlInstance) {
    const allDecorations = yamlInstance.getModel().getAllDecorations();
    yamlInstance.removeDecorations(
      allDecorations.map((decoration) => decoration.id),
    );
  }
};

const getIndents = ({
  yamlInstance,
  startFrom,
}: {
  yamlInstance: editor.ICodeEditor;
  startFrom: IPosition;
}) => {
  const word = yamlInstance.getModel().getWordAtPosition({
    column: startFrom.column,
    lineNumber: startFrom.lineNumber,
  });
  if (word === null)
    try {
      return getIndents({
        yamlInstance,
        startFrom: {
          column: startFrom.column,
          lineNumber: startFrom.lineNumber + 1,
        },
      });
    } catch (_) {
      return startFrom;
    }
  return startFrom;
};

export const nodeSelectAndHighlight = ({
  yamlInstance,
  action,
}: {
  yamlInstance: editor.ICodeEditor;
  action: EditorActions;
}) => {
  const { id } = action.nodeId;
  removeAllDecorations(yamlInstance);
  const position = getPositionInModel(yamlInstance, `${id}:`);
  const posEnd = getIndents({
    yamlInstance,
    startFrom: {
      column: position.range.startColumn,
      lineNumber: position.range.startLineNumber + 1,
    },
  });
  if (!position) return;
  yamlInstance.revealLineNearTop(position?.range?.endLineNumber || 0, 1);

  const highlightedValue = {
    range: {
      startColumn: position.range.startColumn,
      startLineNumber: position.range.startLineNumber,
      endColumn: posEnd.column,
      endLineNumber: posEnd.lineNumber - 1,
    },
    options: {
      isWholeLine: true,
      className: 'myLineDecoration',
      minimap: {
        position: 1,
        color: '#4c9fc840',
        darkColor: '#4c9fc840',
      },
    },
  };

  setTimeout(() => {
    yamlInstance.createDecorationsCollection([highlightedValue]);
  }, 0);
};

export const nodeSelect = ({
  yamlInstance,
  action,
}: {
  yamlInstance: editor.ICodeEditor;
  action: EditorActions;
}) => {
  const { id } = action.nodeId;
  removeAllDecorations(yamlInstance);
  const position = getPositionInModel(yamlInstance, `${id}:`);
  if (!position) return;
  yamlInstance.revealLineNearTop(position?.range?.endLineNumber || 0, 1);

  const highlightedValue = {
    range: position.range,
    options: {
      isWholeLine: true,
      className: 'myLineDecoration',
      minimap: {
        position: 1,
        color: '#4c9fc840',
        darkColor: '#4c9fc840',
      },
    },
  };
  setTimeout(() => {
    yamlInstance.createDecorationsCollection([highlightedValue]);
  }, 0);
};

export const lineHighlighted = ({
  yamlInstance,
  action,
}: {
  yamlInstance: editor.ICodeEditor;
  action: EditorActions;
}) => {
  handleCleanDecorations(yamlInstance);
  const highlights = [
    {
      range: new Range(action.startLine, 1, action.endLine, 1),
      options: {
        isWholeLine: true,
        className: 'myLineDecoration',
        minimap: { position: 1, color: '#4c9fc840', darkColor: '#4c9fc840' },
      },
    },
  ];
  yamlInstance.setPosition({
    lineNumber: action.startLine,
    column: 1,
  });
  yamlInstance.revealLineInCenter(action.startLine, 1);
  yamlInstance.createDecorationsCollection(highlights);
};

export const lineSelect = ({
  yamlInstance,
  action,
}: {
  yamlInstance: editor.ICodeEditor;
  action: EditorActions;
}) => {
  const { line, column } = action;
  yamlInstance.setPosition({
    lineNumber: line,
    column,
  });
  yamlInstance.revealLineInCenter(line, column);
};
