import React, { useState, useEffect, useMemo } from 'react';
import { FormControl, InputLabel, MenuItem, TextField } from '@mui/material';
import Input from '../Input';
import { ModalContent, StyledAutocomplete } from './styles';
import DialogComponent from './DialogComponent';
import { useGetSitesQuery } from '../../redux/services/sites/api';
import {
  useGetSystemByIdQuery,
  useUpdateSystemMutation,
} from '../../redux/services/systems/api';
import useSnackbar from '../../hooks/useSnackbar';
import { useGetCurrentUserQuery } from '../../redux/services/atreus/api';
import useOidcCloud from '../../hooks/useOidcCloud';
import { useAppSelector } from '../../redux/store';
import { IAppState } from '../../typescript/interfaces/appstate.interface';
import { Site } from '../../redux/services/sites/types';

interface Props {
  isOpened: boolean;
  closeModal: () => void;
  id: string;
}

const titleModal = 'Edit system';

const EditSystem = (props: Props): JSX.Element => {
  const { isOpened, closeModal, id } = props;

  const { data: systemData } = useGetSystemByIdQuery(id, { skip: !id });

  const defaultState = useMemo(() => {
    if (!systemData) return undefined;
    return {
      name: systemData?.name,
      description: systemData?.description,
      site: systemData?.site,
    };
  }, [systemData]);

  const [system, setSystem] = useState(defaultState);

  const { accessToken } = useOidcCloud();
  const { data: user } = useGetCurrentUserQuery(accessToken, {
    skip: !accessToken,
  });

  const { data: sitesSelector } = useGetSitesQuery();

  const [updateSystem, { isSuccess: isEditSuccess, error: updateError }] =
    useUpdateSystemMutation();

  const { showForbiddenError, showSuccess } = useSnackbar();

  const sites = useMemo(
    () =>
      sitesSelector?.filter((sitesLocal) => sitesLocal.id !== 'ALLSITES') || [],
    [sitesSelector],
  );

  useEffect(() => {
    if (!defaultState) return;
    setSystem(defaultState);
  }, [defaultState]);

  useEffect(() => {
    if (isEditSuccess) {
      showSuccess(`${system?.name} system has been successfully updated`);
      closeModal();
    }
  }, [isEditSuccess]);

  const handleChange =
    (name: string) => (event: { target: { value: string } }) => {
      setSystem({ ...system, [name]: event.target.value });
    };

  const handleEditSystem = () => {
    const { name, description, site } = system;
    const data = {
      name,
      description,
      site,
      modifiedBy: user?.id,
      modifiedAt: new Date().toUTCString(),
    };

    updateSystem({ id: systemData.id, data });
  };

  useEffect(() => {
    showForbiddenError({
      error: updateError,
      customForbiddenMessage:
        "You don't have enough permissions to update system",
      customDefaultMessage: 'Something went wrong. Please try again later',
    });
  }, [updateError]);

  const isActionButtonDisabled = useMemo(() => {
    if (!system?.name) return true;
    const { site } = system;
    const stateName = system.name.trim();

    return !stateName || stateName?.length > 40 || !site;
  }, [system]);

  if (!systemData) return null;

  return (
    <DialogComponent
      isOpened={isOpened}
      closeModal={closeModal}
      title={titleModal}
      actionTitle="Save"
      handleAction={handleEditSystem}
      isActionButtonDisabled={isActionButtonDisabled}
    >
      <ModalContent>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="name">
            <span>System name</span>
            <span>{system?.name?.length}/40</span>
          </InputLabel>
          <Input
            value={system?.name}
            onChange={handleChange('name')}
            placeholder="Enter system name"
            id="name"
            inputProps={{
              maxLength: 40,
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="site">
            Sites
          </InputLabel>
          <StyledAutocomplete
            id="site"
            options={sites}
            getOptionKey={(option) => {
              const op = sites.find(
                (s) => s?.id === (option as Site)?.id || s?.id === option,
              );
              return op?.id || '';
            }}
            getOptionLabel={(option) => {
              const op = sites.find(
                (s) => s?.id === (option as Site)?.id || s?.id === option,
              );
              return op?.name || '';
            }}
            value={system?.site || null}
            disabled={sites?.length === 0}
            renderInput={(param) => (
              <>
                {sites?.length > 0 ? (
                  <TextField
                    {...param}
                    variant="outlined"
                    placeholder="Select sites"
                  />
                ) : (
                  <MenuItem value={0}>No available sites</MenuItem>
                )}
              </>
            )}
            onChange={(e, newVal) => {
              setSystem((prev) => ({ ...prev, site: (newVal as Site)?.id }));
            }}
          />
        </FormControl>
        <FormControl sx={{ mb: 0 }} fullWidth>
          <InputLabel shrink htmlFor="description">
            <span>Description</span>
            <span>{system?.description?.length}/1024</span>
          </InputLabel>
          <Input
            value={system?.description}
            onChange={handleChange('description')}
            placeholder="Enter description"
            id="description"
            multiline
            rows={4}
            maxRows={8}
            sx={{ p: 0 }}
            inputProps={{
              maxLength: 1024,
            }}
          />
        </FormControl>
      </ModalContent>
    </DialogComponent>
  );
};

export default EditSystem;
