/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const identity = (data: any) => data;

export const isFetchBaseQueryErrorType = (
  error: any,
): error is FetchBaseQueryError => {
  if (typeof error === 'boolean') return false;
  return error && 'status' in error;
};

export const isSerializedError = (error: any): error is SerializedError => {
  if (typeof error === 'boolean') return false;
  return error && 'data' in error;
};

export const isForbiddenError = (error: any): boolean =>
  isFetchBaseQueryErrorType(error) && error.status === 403;

export const isUnprocessableEntityError = (error: any): boolean =>
  isFetchBaseQueryErrorType(error) && error.status === 422;

export const isBadGatewayError = (error: any): boolean =>
  isFetchBaseQueryErrorType(error) &&
  (error.status === 502 ||
    ('originalStatus' in error && error.originalStatus === 502));

export const isUnauthorizedError = (error: any): boolean =>
  isFetchBaseQueryErrorType(error) &&
  (error.status === 401 ||
    ('originalStatus' in error && error.originalStatus === 401));

export const isNameAlreadyInUseError = (error: any): boolean =>
  isSerializedError(error) &&
  'data' in error &&
  isErrorDetailArray(error.data) &&
  error.data.detail.some(
    (detail) =>
      detail.msg.toLowerCase().includes('name') &&
      detail.msg.toLowerCase().includes('already in use'),
  );

export const isUniqueViolationError = (error: any): boolean =>
  isSerializedError(error) &&
  'data' in error &&
  typeof error.data === 'object' &&
  (('error' in error.data &&
    typeof error.data.error === 'object' &&
    'message' in error.data.error &&
    typeof error.data.error.message === 'string' &&
    error.data.error.message.includes('Unique violation error')) ||
    ('message' in error.data &&
      typeof error.data.message === 'string' &&
      error.data.message.includes('Unique violation error')));

const isErrorMessageString = (error: any): error is { message: string } =>
  typeof error === 'object' &&
  'message' in error &&
  typeof error.message === 'string';

const isErrorDetailString = (error: any): error is { detail: string } =>
  typeof error === 'object' &&
  'detail' in error &&
  typeof error.detail === 'string';

interface ErrorDetailArrayElement {
  loc: string[];
  msg: string;
  type: string;
}

const isErrorDetailArray = (
  error: any,
): error is { detail: ErrorDetailArrayElement[] } =>
  typeof error === 'object' && 'detail' in error && Array.isArray(error.detail);

export const tryGetErrorMessageOrNull = (error: any): string | null => {
  if (
    !error ||
    !isSerializedError(error) ||
    !('data' in error) ||
    typeof error.data !== 'object' ||
    (!isErrorMessageString(error.data) && !isErrorDetailString(error.data))
  ) {
    return null;
  }
  if (isErrorMessageString(error.data)) {
    return error.data.message;
  }
  return error.data.detail ?? null;
};
