import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';
import { IFNSView } from '../../../typescript/interfaces/fnsProject.interface';
import { sortArrayByProperty } from '../../../utils/stringUtils';

const BASE_URL = apiBaseUrlV1('control/v1');

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getFnsViews: builder.query<IFNSView[] | undefined, void>({
      query: () => `${BASE_URL}/fnsViews`,
      providesTags: ['FnsViews'],
      transformResponse: (res: IFNSView[] | undefined) =>
        sortArrayByProperty(res, 'name'),
    }),
  }),
});

export const { useGetFnsViewsQuery } = extendedApi;
