import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { setEvent } from '../redux/modules/events';
import {
  isForbiddenError,
  isNameAlreadyInUseError,
  isUniqueViolationError,
} from '../redux/utils';
import { formatErrorMessage } from '../utils/helper';

export interface IError {
  detail?: { msg?: string };
  data: { detail?: string };
  status: number;
  message?: string;
  code?: number;
  reason?: string;
  response?: { status?: number };
}

export type IErrorExtended = IError | FetchBaseQueryError | SerializedError;

export default function useSnackbar() {
  const dispatch = useDispatch();

  const showError = useCallback(
    (message: string, error?: IError | undefined) => {
      if (error && error.status === 403) return;
      dispatch(
        setEvent({
          snackbar: { type: 'Error', message },
        }),
      );
    },
    [],
  );

  const showSuccess = useCallback((message: string) => {
    dispatch(
      setEvent({
        snackbar: { type: 'Success', message },
      }),
    );
  }, []);

  const showInfo = useCallback((message: string) => {
    dispatch(
      setEvent({
        snackbar: { type: 'Info', message },
      }),
    );
  }, []);

  const showForbiddenError = ({
    error,
    customDefaultMessage,
    customForbiddenMessage,
    customUniqueViolationMessage,
  }: {
    error: IErrorExtended | undefined;
    customDefaultMessage?: string | undefined;
    customForbiddenMessage?: string;
    customUniqueViolationMessage?: string;
  }) => {
    if (!error) return;
    if (isForbiddenError(error)) {
      showError(
        customForbiddenMessage ||
          "You don't have enough permissions to do that",
      );
      return;
    }
    if (isUniqueViolationError(error) || isNameAlreadyInUseError(error)) {
      showError(customUniqueViolationMessage || 'Resource already exists');
      return;
    }
    showError(
      customDefaultMessage ||
        formatErrorMessage(error) ||
        'Something happened, your request failed',
    );
  };

  return { showError, showForbiddenError, showSuccess, showInfo };
}
