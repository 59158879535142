import React, { Dispatch, SetStateAction, ChangeEvent } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import {
  AddRounded,
  FilterListRounded,
  MapRounded,
  SearchRounded,
} from '@mui/icons-material';
import { ToggleBtn } from './styles';
import SiteFilter from '../../../common/SiteFilter';
import StatusFilter from '../StatusFilter';
import Search from '../../../common/Search';
import { ListIcon } from '../../../icons';
import { SwitchBtn, SwitchWrap } from '../../../common/styles/switch';
import { Site } from '../../../redux/services/sites/types';
import { useAppSelector } from '../../../redux/store';
import { IAppState } from '../../../typescript/interfaces/appstate.interface';

interface Props {
  mapView: boolean;
  isSearch: boolean;
  searchValue: string;
  handleSearchInput: (_ev: ChangeEvent<HTMLInputElement>) => void;
  handleCollapseSearch: () => void;
  status: string[];
  statuses: string[];
  handleStatusChange: (_ev: any) => void;
  isFilter: boolean;
  handleToggleFilter: () => void;
  sites: Site[];
  activeSites: string[];
  handleExpandSearch: () => void;
  handleCreateOpen: () => void;
  selection: string[];
  setSelection: Dispatch<SetStateAction<string[]>>;
  onMapView: () => void;
  onListView: () => void;
}

const FilterComponents = ({
  mapView,
  isSearch,
  searchValue,
  handleSearchInput,
  handleCollapseSearch,
  status,
  statuses,
  handleStatusChange,
  isFilter,
  handleToggleFilter,
  sites,
  activeSites,
  handleExpandSearch,
  handleCreateOpen,
  selection,
  setSelection,
  onMapView,
  onListView,
}: Props) => {
  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );

  return (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none', width: '100%' } }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {isSearch || isEdgeEnv ? (
            <Box sx={{ flex: 1, mr: 1 }}>
              <Search
                id="System-search_input"
                className="search"
                placeholder="Search"
                value={searchValue}
                onSearch={handleSearchInput}
                onCancel={handleCollapseSearch}
              />
            </Box>
          ) : (
            <Button
              onClick={handleCreateOpen}
              startIcon={<AddRounded />}
              name="AddSystem"
              sx={{ whiteSpace: 'nowrap' }}
              variant="contained"
              id="SystemList-Button_AddSystem"
            >
              Add system
            </Button>
          )}
          <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
            {!isSearch && (
              <ToggleBtn
                className={isSearch && 'active'}
                onClick={handleExpandSearch}
                id="SystemList-search_button"
              >
                <SearchRounded />
              </ToggleBtn>
            )}
            <ToggleBtn
              className={
                (isFilter ||
                  status.toString() !== 'All' ||
                  sites?.length !== activeSites?.length) &&
                'active'
              }
              onClick={handleToggleFilter}
              id="SystemList-filter_button"
            >
              <FilterListRounded />
            </ToggleBtn>
            {!isEdgeEnv && (
              <SwitchWrap sx={{ ml: 1 }}>
                <Tooltip title="List view">
                  <SwitchBtn
                    onClick={onListView}
                    className={!mapView && 'active'}
                    color="inherit"
                    id="SystemList-Switch-list_view"
                  >
                    <ListIcon />
                  </SwitchBtn>
                </Tooltip>
                <Tooltip title="Map view">
                  <SwitchBtn
                    onClick={onMapView}
                    className={mapView && 'active'}
                    color="inherit"
                    id="SystemList-Switch-map_view"
                  >
                    <MapRounded />
                  </SwitchBtn>
                </Tooltip>
              </SwitchWrap>
            )}
          </Box>
        </Box>
        {isFilter && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <SiteFilter
              sx={{
                ml: 0,
                width: 'calc(50% - 0.375rem)',
                mr: '0.375rem',
              }}
              selection={selection}
              setSelection={setSelection}
            />
            <StatusFilter
              value={status}
              onChange={handleStatusChange}
              statuses={statuses}
            />
          </Box>
        )}
      </Box>
      {mapView && (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Search
            id="System-map_search"
            className="search"
            placeholder="Search"
            value={searchValue}
            onSearch={handleSearchInput}
            onCancel={handleCollapseSearch}
            defaultExpanded
          />
        </Box>
      )}
    </>
  );
};

export default FilterComponents;
