import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';
import { IPort } from '../../../typescript/interfaces/ports.interface';

const BASE_URL = `${apiBaseUrlV1('structure/v1')}/ports`;

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPorts: builder.query<IPort[], string>({
      query: (rootAggregate) =>
        `${BASE_URL}?rootAggregate=${rootAggregate.toUpperCase()}`,
      providesTags: ['Ports'],
      transformResponse: (res: IPort[]) =>
        [...res].sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        }),
    }),
    getPortById: builder.query<IPort, string>({
      query: (id: string) => `${BASE_URL}/${id}`,
    }),
  }),
});

export const { useGetPortsQuery, useGetPortByIdQuery } = extendedApi;
