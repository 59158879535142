import 'monaco-editor';
import { editor, MarkerSeverity } from 'monaco-editor/esm/vs/editor/editor.api';
import { Node, NodePositionChange, XYPosition } from 'react-flow-renderer';
import { IDataValidate } from './EditArea';

type GetHelperLinesResult = {
  horizontal?: number;
  vertical?: number;
  snapPosition: Partial<XYPosition>;
};

interface ConfigTemplateProps {
  name?: string;
  description?: string;
  components?: any;
  connectors?: any;
}

export const createVisualTemplate = (data: ConfigTemplateProps) =>
  `category: null
description: ${data.name || 'Default component description'}
name: ${data.name || 'Component'}
flairs:
  input1:
    flow: INLET
    heading: LEFT
    placement:
      x: 0
      y: -15
    size: null
    type: portAnchor
  input2:
    flow: INLET
    heading: LEFT
    placement:
      x: 0
      y: 0
    size: null
    type: portAnchor
  input3:
    flow: INLET
    heading: LEFT
    placement:
      x: 0
      y: 15
    size: null
    type: portAnchor
  input4:
    flow: INLET
    heading: RIGHT
    placement:
      x: 0
      y: -15
    size: null
    type: portAnchor
  input5:
    flow: INLET
    heading: RIGHT
    placement:
      x: 0
      y: 0
    size: null
    type: portAnchor
  input6:
    flow: INLET
    heading: RIGHT
    placement:
      x: 0
      y: 15
    size: null
    type: portAnchor
  nameLabel:
    background_color: null
    default: Pixer
    placement:
      x: 19
      y: 23
    prefix: null
    size: null
    suffix: null
    text_color: null
    text_size: M
    type: label
  output1:
    flow: OUTLET
    heading: null
    placement:
      x: -25
      y: 70
    size: null
    type: portAnchor
  output2:
    flow: OUTLET
    heading: null
    placement:
      x: 25
      y: 70
    size: null
    type: portAnchor
size: 
  width: 72
  height: 72
`;

export const createConfigTemplate = (data: ConfigTemplateProps) =>
  `backgroundColor: '#fcfdfe'
components:
  Display:
    instruments:
      Display:
        attributes:
          Display:
            default:
              d: 0
              i: 0
              p: 0
              setPoint: 0
            stateSchemaId: example.procaaso.io/schemas/demo_stand/DisplayData
    ports: {}
    render:
      - 175
      - 10
    visual:
      $ref: '#/visuals/Display'
      flairs:
        dLabel:
          backgroundColor: transparent
          default: 'D:'
          placement:
            x: -30
            y: 20
          textColor: black
          textSize: XS
          type: label
        dPV:
          backgroundColor: transparent
          default: '0.00'
          placement:
            x: 20
            y: 20
          textColor: Black
          textSize: XS
          type: label
        iLabel:
          backgroundColor: transparent
          default: 'I:'
          placement:
            x: -30
            y: 0
          textColor: black
          textSize: XS
          type: label
        iPV:
          backgroundColor: transparent
          default: '0.00'
          placement:
            x: 20
            y: 0
          textColor: Black
          textSize: XS
          type: label
        nameLabel:
          backgroundColor: transparent
          default: PID Values
          placement:
            x: 0
            y: -45
          textColor: black
          textSize: XS
          type: label
        pLabel:
          backgroundColor: transparent
          default: 'P:'
          placement:
            x: -30
            y: -20
          textColor: black
          textSize: XS
          type: label
        pPV:
          backgroundColor: transparent
          default: '0.00'
          placement:
            x: 20
            y: -20
          textColor: Black
          textSize: XS
          type: label
        setpointLabel:
          backgroundColor: transparent
          default: 'Set Point:'
          placement:
            x: -50
            y: 40
          textColor: black
          textSize: XS
          type: label
        setpointPV:
          backgroundColor: transparent
          default: '0.00'
          placement:
            x: 35
            y: 40
          suffix: l/Min
          textColor: Black
          textSize: XS
          type: label
      input:
        dPV: $.stream.Display.Display.d
        iPV: $.stream.Display.Display.i
        pPV: $.stream.Display.Display.p
        setpointPV: $.stream.Display.Display.setPoint
      size:
        height: 200
        width: 200
  Flow Sensor:
    instruments:
      Sensor:
        attributes:
          data:
            default:
              PV: 0
            stateSchemaId: example.procaaso.io/schemas/demo_stand/FlowSensorData
    ports:
      input:
        anchor: input
      output:
        anchor: output
    render:
      - -175
      - 20
    visual:
      $ref: '#/visuals/Flow Sensor'
      flairs:
        input:
          flow: INLET
          placement:
            x: -27
            y: 95
          type: portAnchor
        nameLabel:
          backgroundColor: transparent
          default: Flow Sensor
          placement:
            x: 0
            y: 60
          textColor: black
          textSize: XS
          type: label
        output:
          flow: OUTLET
          placement:
            x: 21
            y: 4
          type: portAnchor
        pressure:
          backgroundColor: transparent
          default: '0.0'
          placement:
            x: 0
            y: -60
          suffix: l/Min
          textColor: black
          textSize: XS
          type: label
      input:
        nameLabel: $.none
        pressure: $.stream.Sensor.data.PV
      size:
        height: 100
        width: 50
  Liquid Vessel:
    instruments: {}
    ports:
      feed1:
        anchor: feed1
      feed2:
        anchor: feed2
    render:
      - -123
      - -2
    visual:
      $ref: '#/visuals/Liquid Vessel'
      flairs:
        feed1:
          placement:
            x: -45
            y: 106
          type: portAnchor
        feed2:
          placement:
            x: 39
            y: 106
          type: portAnchor
        fillBar: null
        fillPercentage: null
        nameLabel:
          backgroundColor: transparent
          default: Liquid Vessel
          placement:
            x: 0
            y: 0
          textColor: black
          textSize: L
          type: label
        weight:
          backgroundColor: '#ededed'
          default: '0.00'
          placement:
            x: 0
            y: 70
          suffix: kg
          textColor: transparent
          textSize: XL
          type: label
      input:
        fillBar: $.stream.wit01.state.net
        fillPercentage: $.stream.wit01.state.net
        name: $.name
        weight: $.stream.wit01.state.pv
      size:
        height: 320
        width: 320
  Pixer:
    instruments:
      Pump:
        attributes:
          control:
            default:
              sp: 0
            stateSchemaId: example.procaaso.io/schemas/demo_stand/PumpData
          mutation:
            default:
              sp: -999
            stateSchemaId: example.procaaso.io/schemas/demo_stand/PumpMutation
    ports:
      input1:
        anchor: input1
      input2:
        anchor: input2
      input3:
        anchor: input3
      input4:
        anchor: input4
      input5:
        anchor: input5
      input6:
        anchor: input6
      output1:
        anchor: output1
      output2:
        anchor: output2
    render:
      - 2
      - 297
    visual:
      $ref: '#/visuals/Pixer'
      flairs:
        input1:
          flow: OUTLET
          placement:
            x: -41
            y: 50
          type: portAnchor
        input2:
          flow: OUTLET
          placement:
            x: -41
            y: 50
          type: portAnchor
        input3:
          flow: OUTLET
          placement:
            x: -41
            y: 50
          type: portAnchor
        input4:
          flow: OUTLET
          placement:
            x: -41
            y: 50
          type: portAnchor
        input5:
          flow: OUTLET
          placement:
            x: -41
            y: 50
          type: portAnchor
        input6:
          flow: OUTLET
          placement:
            x: -41
            y: 50
          type: portAnchor
        nameLabel:
          backgroundColor: transparent
          default: Pixer
          placement:
            x: 0
            y: 10
          textColor: black
          textSize: M
          type: label
        output1:
          flow: OUTLET
          placement:
            x: -41
            y: 50
          type: portAnchor
        output2:
          flow: OUTLET
          placement:
            x: 35
            y: 50
          type: portAnchor
        speed:
          backgroundColor: transparent
          default: '0.00'
          placement:
            x: 0
            y: 55
          suffix: RPM
          textColor: Black
          textSize: XL
          type: label
      input:
        speed: $.stream.Pump.control.sp
  Pressure Sensor:
    instruments:
      Sensor:
        attributes:
          data:
            default:
              PV: 0
            stateSchemaId: example.procaaso.io/schemas/demo_stand/PressureSensorData
    ports:
      input:
        anchor: input
      output:
        anchor: output
    render:
      - -185
      - 180
    visual:
      $ref: '#/visuals/PendoTech Sensor'
      flairs:
        input:
          flow: INLET
          placement:
            x: 30
            y: 50
          type: portAnchor
        nameLabel:
          backgroundColor: transparent
          default: Pressure Sensor
          placement:
            x: 0
            y: 40
          textColor: black
          textSize: XS
          type: label
        output:
          flow: OUTLET
          placement:
            x: 30
            y: 60
          type: portAnchor
        pressure:
          backgroundColor: transparent
          default: '0.0'
          placement:
            x: 0
            y: -40
          suffix: PSI
          textColor: black
          textSize: XS
          type: label
      input:
        nameLabel: $.none
        pressure: $.stream.Sensor.data.PV
      size:
        height: 72
        width: 72
  Valve:
    instruments:
      ValveControl:
        attributes:
          control:
            default:
              positionPV: 0
              runRoutine: false
            stateSchemaId: example.procaaso.io/schemas/demo_stand/PumpControl
          ioMutation:
            default:
              actuationNum: 0
              runRoutine: false
            stateSchemaId: example.procaaso.io/schemas/demo_stand/PumpIOMutation
          logic:
            default:
              close: false
              d: 0
              i: 0
              open: false
              p: 0
              pidSP: 0
              positionPercentage: 0
              positionSP: 0
            stateSchemaId: example.procaaso.io/schemas/demo_stand/PumpLogic
          mutation:
            default:
              actuationNum: 0
              close: false
              d: 0
              enablePID: false
              i: 0
              open: false
              p: 0
              pidSP: 0
              runRoutine: false
            stateSchemaId: example.procaaso.io/schemas/demo_stand/PumpMutation
    ports:
      input2:
        anchor: input2
      output1:
        anchor: output1
    render:
      - -240
      - 300
    visual:
      $ref: '#/visuals/Control Valve'
      flairs:
        input2:
          flow: INLET
          heading: RIGHT
          placement:
            x: -60
            y: 9
          type: portAnchor
        name:
          backgroundColor: transparent
          default: Valve
          placement:
            x: 0
            y: 40
          textColor: black
          textSize: M
          type: label
        openPercent:
          backgroundColor: transparent
          default: '0.0'
          placement:
            x: 0
            y: -40
          suffix: '%'
          textColor: black
          textSize: M
          type: label
        output1:
          flow: OUTLET
          heading: LEFT
          placement:
            x: 60
            y: 9
          type: portAnchor
      input:
        name: $.none
        openPercent: $.stream.ValveControl.logic.positionPercentage
      size:
        height: 60
        width: 180
  phase:
    componentType: hidden
    instruments:
      phase:
        attributes:
          cmd:
            default:
              abort: false
              hold: false
              load: false
              path: ''
              restart: false
              start: false
            stateSchemaId: example.procaaso.ioschemas/test_stand/BatchCmds
          state:
            default:
              abort: false
              display: ''
              hold: false
              load: false
              path: ''
              restart: false
              start: false
              state: 0
            stateSchemaId: example.procaaso.ioschemas/test_stand/BatchCmdState
    ports: {}
    render:
      - -736
      - 111
connectors:
  Flow Sensor - output to Pressure Sensor - input:
    instruments: {}
    ports:
      - $ref: '#/components/Flow Sensor/ports/output'
      - $ref: '#/components/Pressure Sensor/ports/input'
    waypoints:
      - x: 74
        y: 7
      - x: 72
        y: 213
  Pixer - output1 to Valve - input2:
    instruments: {}
    ports:
      - $ref: '#/components/Pixer/ports/output1'
      - $ref: '#/components/Valve/ports/input2'
    waypoints: []
  Pixer - output2 to Liquid Vessel - feed2:
    instruments: {}
    ports:
      - $ref: '#/components/Pixer/ports/output2'
      - $ref: '#/components/Liquid Vessel/ports/feed2'
    waypoints:
      - x: 143
        y: 36
      - x: 189
        y: -210
  Pressure Sensor - output to Liquid Vessel - feed1:
    instruments: {}
    ports:
      - $ref: '#/components/Pressure Sensor/ports/output'
      - $ref: '#/components/Liquid Vessel/ports/feed1'
    waypoints:
      - x: 162
        y: 5
  Valve - output1 to Flow Sensor - input:
    instruments: {}
    ports:
      - $ref: '#/components/Valve/ports/output1'
      - $ref: '#/components/Flow Sensor/ports/input'
    waypoints:
      - x: -50
        y: -198
${data?.name ? `name: ${data.name}` : 'name: Default Template'}
${
  data?.description
    ? `description: ${data.description}`
    : 'description: A default template'
}
operationMode: CONTINUOUS
trend:
  Chart 1:
    - group: Flow
      metric: sensor
  metrics:
    Flow:
      sensor:
        query: $.Flow_Sensor.Sensor.data.PV
visuals:
  Control Valve:
    flairs: {}
    id: VSUL3ED93933E52F47D9ABDB64C7F8E30373
    version: 0
  Display:
    flairs: {}
    id: VSULA88B0657511C4F429967CBF4A2F65508
    version: 0
  Flow Sensor:
    flairs: {}
    id: VSULB6FCEC6C78EE4B88B9B0CB7232C8D020
    version: 0
  PendoTech Sensor:
    flairs: {}
    id: VSULA01ED408E112471FBA38CCAC4733F891
    version: 0
  Pixer:
    flairs: {}
    id: VSUL9429A6C09BAA4579A81076780DDE7E38
    version: 0
  Liquid Vessel:
    flairs: {}
    id: VSUL6158C105E5E84AF687A0DB1344571730
    version: 0
`;

export const resetDecorations = (yamlInstance: editor.ICodeEditor): void => {
  yamlInstance.createDecorationsCollection([]);
};

export const clearMarkers = (yamlInstance: editor.ICodeEditor): void => {
  const model = yamlInstance.getModel();
  if (!model) return;

  editor.setModelMarkers(model, 'yaml-linter', []); // Удаляем все маркеры
};

// this utility function can be called with a position change (inside onNodesChange)
// it checks all other nodes and calculated the helper line positions and the position where the current node should snap to
export function getHelperLines(
  change: NodePositionChange,
  nodes: Node[],
  distance = 5,
): GetHelperLinesResult {
  const defaultResult = {
    horizontal: undefined,
    vertical: undefined,
    snapPosition: { x: undefined, y: undefined },
  };
  const nodeA = nodes.find((node) => node.id === change.id);

  if (!nodeA || !change.position) {
    return defaultResult;
  }

  const nodeABounds = {
    left: change.position.x,
    right: change.position.x + (nodeA.width ?? 0),
    top: change.position.y,
    bottom: change.position.y + (nodeA.height ?? 0),
    width: nodeA.width ?? 0,
    height: nodeA.height ?? 0,
  };

  let horizontalDistance = distance;
  let verticalDistance = distance;

  return nodes
    .filter((node) => node.id !== nodeA.id)
    .reduce<GetHelperLinesResult>((result, nodeB) => {
      const nodeBBounds = {
        left: nodeB.position.x,
        right: nodeB.position.x + (nodeB.width ?? 0),
        top: nodeB.position.y,
        bottom: nodeB.position.y + (nodeB.height ?? 0),
        width: nodeB.width ?? 0,
        height: nodeB.height ?? 0,
      };

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //  |
      //  |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left);

      if (distanceLeftLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left;
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceLeftLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceRightRight = Math.abs(
        nodeABounds.right - nodeBBounds.right,
      );

      if (distanceRightRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right - nodeABounds.width;
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceRightRight;
      }

      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     A     |
      //              |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right);

      if (distanceLeftRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right;
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceLeftRight;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     B     |
      //              |___________|
      const distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left);

      if (distanceRightLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left - nodeABounds.width;
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceRightLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|     |___________|
      const distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top);

      if (distanceTopTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top;
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceTopTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|_________________
      //                    |           |
      //                    |     B     |
      //                    |___________|
      const distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top);

      if (distanceBottomTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top - nodeABounds.height;
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceBottomTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|_____|___________|
      const distanceBottomBottom = Math.abs(
        nodeABounds.bottom - nodeBBounds.bottom,
      );

      if (distanceBottomBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height;
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceBottomBottom;
      }

      //                    |‾‾‾‾‾‾‾‾‾‾‾|
      //                    |     B     |
      //                    |           |
      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
      //  |     A     |
      //  |___________|
      const distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom);

      if (distanceTopBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom;
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceTopBottom;
      }

      return result;
    }, defaultResult);
}

export const generateErrorsMarkers = (
  yamlInstance: editor.ICodeEditor,
  error: IDataValidate,
): editor.IMarker[] => {
  const errors = error.data.detail;
  const model = yamlInstance.getModel();

  if (!model) return [];
  if (!errors) return [];

  const seenPositions = new Set<string>();
  const markers = errors
    .map(({ loc, msg }) => {
      let lastMatch: editor.FindMatch | null = null;
      let currentPosition = { lineNumber: 1, column: 1 };

      loc.forEach((segment) => {
        const matches = model.findMatches(
          segment,
          false,
          false,
          false,
          null,
          true,
        );
        lastMatch =
          matches.find(
            (match) =>
              match.range.startLineNumber - 1 >= currentPosition.lineNumber,
          ) || matches[0];

        currentPosition = {
          lineNumber: lastMatch?.range.endLineNumber ?? 1,
          column: lastMatch?.range.endColumn ?? 1,
        };
      });

      if (!lastMatch) return null;

      const markerKey = `${lastMatch.range.startLineNumber}:${lastMatch.range.startColumn}-${lastMatch.range.endLineNumber}:${lastMatch.range.endColumn}`;

      if (seenPositions.has(markerKey)) return null;
      seenPositions.add(markerKey);

      return {
        severity: MarkerSeverity.Error,
        message: msg,
        startLineNumber: lastMatch.range.startLineNumber,
        startColumn: lastMatch.range.startColumn,
        endLineNumber: lastMatch.range.endLineNumber,
        endColumn: lastMatch.range.endColumn,
        owner: model.getLanguageId(),
        resource: model.uri,
      };
    })
    .filter(Boolean);

  generateMarkers(yamlInstance, markers);

  return markers;
};

export const generateMarkers = (
  yamlInstance: editor.ICodeEditor,
  markers: editor.IMarker[],
): void => {
  const model = yamlInstance.getModel();
  if (!model) return;
  clearMarkers(yamlInstance);
  editor.setModelMarkers(model, 'yaml-linter', markers.length ? markers : []);
};
