import { ComponentType } from 'react';
import { AvailableDependencies, PiletMetadataV1 } from 'piral-base/lib/types';
import { createContainersApi } from 'piral-containers';
import { createProtoPiletApi } from 'pc-piral-proto-api';
import {
  PiralInstance,
  PiletMetadata,
  createInstance,
  ErrorInfoProps,
} from 'piral-core';
import { createFetchApi } from 'piral-fetch';
import { getDockFeed } from '../api/viewFeeds';
import Loader from '../common/Loader';

export function shareDependencies(
  dependencies: AvailableDependencies,
): AvailableDependencies {
  /* eslint-disable global-require */
  /* eslint-disable import/no-extraneous-dependencies */
  /*  The below list of dependencies should roughly match what is
        passed through `pc-piral-instance/packge.json pilet.externals` list and what is 
        found at https://docs.piral.io/guidelines/tutorials/15-share-dependencies#implicit-sharing-from-the-app-shell
    */
  return {
    ...dependencies,
    // Piral default shares
    react: require('react'),
    'react-dom': require('react-dom'),
    'react-router': require('react-router'),
    'react-router-dom': require('react-router-dom'),
    history: require('history'),
    tslib: require('tslib'),
    'path-to-regexp': require('path-to-regexp'),
    '@libre/atom': require('@libre/atom'),
    '@dbeining/react-atom': require('@dbeining/react-atom'),
    // Paracloud specific shares
    'pc-piral-proto-api': require('pc-piral-proto-api'),
    '@mui/lab': require('@mui/lab'),
    '@mui/material': require('@mui/material'),
    '@mui/x-date-pickers': require('@mui/x-date-pickers'),
    graphql: require('graphql'),
    'graphql-tag': require('graphql-tag'),
    moment: require('moment'),
    'react-redux': require('react-redux'),
    recharts: require('recharts'),
    redux: require('redux'),
    'redux-actions': require('redux-actions'),
    'redux-promise-middleware': require('redux-promise-middleware'),
    uplot: require('uplot'),
    'uplot-react': require('uplot-react'),
    'subscriptions-transport-ws': require('subscriptions-transport-ws'),
  };
  /* eslint-enable import/no-extraneous-dependencies */
  /* eslint-enable global-require */
}

export const defaultPlugins = [createContainersApi(), createProtoPiletApi()];

export type ParacloudPiletMetadataConfig = {
  context: Record<string, any>;
} & Record<string, any>;

export interface ParacloudPiletMetadataV1 extends PiletMetadataV1 {
  config: ParacloudPiletMetadataConfig;
}

interface CreateInstanceProps {
  id: string;
  feed: string | ParacloudPiletMetadataV1;
  setMetadata: React.Dispatch<React.SetStateAction<PiletMetadata | undefined>>;
  systemControlId: string;
  token: string;
  errorComponent: ComponentType<ErrorInfoProps>;
}

export const createInstanceFn = ({
  id,
  feed,
  setMetadata,
  systemControlId,
  token,
  errorComponent,
}: CreateInstanceProps): PiralInstance => {
  const requestPilets = async (): Promise<Array<ParacloudPiletMetadataV1>> => {
    const piletMetas: Array<ParacloudPiletMetadataV1> =
      typeof feed === 'string' ? await getDockFeed(id, feed) : [feed];

    if (piletMetas && piletMetas.length > 0) {
      try {
        const piletContext = piletMetas[0]?.config?.context[systemControlId];
        setMetadata(piletContext || undefined);
      } catch {
        setMetadata(undefined);
      }
      return [piletMetas[0]];
    }
    return undefined;
  };

  const instance = createInstance({
    requestPilets,
    plugins: [
      ...defaultPlugins,
      createFetchApi({
        default: {
          mode: 'no-cors',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      }),
    ],
    shareDependencies,
    state: {
      components: {
        ErrorInfo: errorComponent,
        LoadingIndicator: Loader,
      },
    },
  });

  return instance;
};

export const emitMetadataFn = ({
  instance,
  metadata,
}: {
  instance: PiralInstance;
  metadata: PiletMetadata;
}): void => {
  if (Object.keys(metadata).length !== 0) {
    instance.root.setData('context', metadata);
    instance.root.emit('contextAvailable', true);
    return;
  }
  if (instance !== undefined) {
    instance.root.emit('contextAvailable', false);
  }
};

export const emitDataFn = ({
  instance,
  name,
  data,
}: {
  instance: PiralInstance;
  name: string;
  data: any;
}): void => {
  if (instance) {
    instance.root.setData(name, data);
  }
};
