/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { createInstance, Piral, PiralInstance, SetError } from 'piral-core';
import { apiBaseUrlV1 as urlv1 } from '../../../../../env';
import Loader from '../../../../Loader';
import {
  shareDependencies,
  defaultPlugins,
  ParacloudPiletMetadataV1,
} from '../../../../../utils/piral';
import { getComponentFaceplateFeed } from '../../../../../api/viewFeeds';
import { useGetComponentByIdQuery } from '../../../../../redux/services/components/api';
import { useGetSystemByIdQuery } from '../../../../../redux/services/systems/api';

interface Props {
  data: any;
  id?: string;
}

const Root = styled(Stack)(({ theme }) => ({
  minHeight: '164px',
  padding: theme.spacing(2),
  paddingTop: '2.5rem',
  minWidth: '300px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'none',
  backgroundColor: '#fff',
}));

const Head = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  padding: '0.75rem 1rem',
  color: '#111111',
  '& span': {
    fontSize: '1.5rem',
  },
});

const Close = styled(CloseRounded)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  cursor: 'pointer',
  height: '2rem',
  width: '2rem',
  maxHeight: '2rem',
  maxWidth: '2rem',
  position: 'relative',
  zIndex: 99,
}));

const Content = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  justifyContent: 'center',
});

const DialogCustomNode = (props: Props) => {
  const { data, id } = props;
  const { handleCloseDialogNode, size } = data;
  const { width, height } = size || {};

  const [piral, setPiral] = useState<PiralInstance>();

  const { data: system } = useGetSystemByIdQuery(data?.data?.systemId, {
    skip: !data?.data?.systemId,
  });

  const components = useMemo(() => {
    if (system?.components.length > 0) return system?.components;
    return undefined;
  }, [system]);

  const [componentControlId, setComponentControlId] = useState('');

  const { data: componentControlIdSelector } = useGetComponentByIdQuery(
    data.id,
    { skip: !data.id },
  );

  useEffect(() => {
    if (componentControlIdSelector)
      setComponentControlId(componentControlIdSelector?.id);
  }, [componentControlIdSelector]);

  const { name, feedUrl } = useMemo(() => {
    if (Array.isArray(components)) {
      const [currentComponent] = components.filter((c) => c.id === data.id);

      return {
        ...currentComponent,
        feedUrl: `${urlv1('control')}/v1/components/${data.id}/feed`,
      };
    }
    return { name, feedUrl };
  }, [components, data]);

  const [selectedNodes, setSelectedNodes] = useState(data.selectedNodes);

  useEffect(() => {
    if (JSON.stringify(data.selectedNodes) !== JSON.stringify(selectedNodes)) {
      setSelectedNodes(data.selectedNodes);
    }
  }, [data.selectedNodes]);

  const handleClose = useCallback(() => {
    handleCloseDialogNode(id);
  }, []);

  //@ts-ignore
  const [isPiletFetched, setIsPiletFetched] = useState(false);
  const [key, setKey] = useState(Date.now());
  const [loading] = useState(false);
  const [isErrorNotFound, setErrorNotFound] = useState(false);
  const [piletMetaContext, setPiletMetaContext] = useState({});

  useEffect(() => {
    setKey(Date.now());
    const requestPilets = async (): Promise<
      Array<ParacloudPiletMetadataV1>
    > => {
      const piletMetas: Array<ParacloudPiletMetadataV1> =
        await getComponentFaceplateFeed(data.id);
      if (componentControlId === '') {
        if (componentControlIdSelector)
          setComponentControlId(componentControlIdSelector?.id);
      }

      if (piletMetas && piletMetas.length > 0) {
        setIsPiletFetched(true);
        try {
          const piletMeta = piletMetas[0].config.context[componentControlId];
          setPiletMetaContext(piletMeta || {});
        } catch {
          setPiletMetaContext({});
        }
        return [piletMetas[0]];
      }
      return [];
    };
    setPiral(
      createInstance({
        // @ts-ignore
        requestPilets,
        plugins: defaultPlugins,
        shareDependencies,
        state: {
          errorComponents: {
            loading: () =>
              ErrorShowing('Custom UI loading error. Refetching...'),
            not_found: () => ErrorShowing('Custom UI not found. Refetching...'),
          },
        },
      }),
    );
  }, [loading, isErrorNotFound, componentControlIdSelector]);

  useEffect(() => {
    if (piral !== undefined && Object.keys(piletMetaContext).length !== 0) {
      piral.root.setData('context', piletMetaContext);
      piral.root.emit('contextAvailable', true);
    } else if (piral !== undefined) {
      piral.root.emit('contextAvailable', false);
    }
  }, [piral, piletMetaContext]);

  useEffect(() => {
    setKey(Date.now());
  }, [isPiletFetched]);

  const ErrorShowing = useCallback((message: any) => {
    setErrorNotFound(true);
    return <div>{message}</div>;
  }, []);

  return (
    <Root
      key={key}
      // @ts-ignore
      elevation={3}
      style={{
        width: width || '',
        height: height || '',
      }}
    >
      <Head>
        <span>{name}</span>
        <Close onClick={handleClose} />
      </Head>
      <Content>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {loading ? (
            <Loader />
          ) : (
            <Grid item xs={12} sm container direction="column">
              {feedUrl ? (
                <Piral instance={piral} />
              ) : (
                <Typography sx={{ fontSize: '1.5rem' }}>
                  External feed not set
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Content>
    </Root>
  );
};

DialogCustomNode.defaultProps = {
  id: '',
};

export default React.memo(DialogCustomNode);
