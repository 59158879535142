import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { History } from 'history';
import {
  Button,
  Box,
  Typography,
  styled,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  ListItemIcon,
  Stack,
  Tooltip,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  EditOutlined,
  KeyboardArrowLeftRounded,
  LockOutlined,
  MoreVertRounded,
} from '@mui/icons-material';
import { ROUTES_PATH } from '../../constants';
import { TrashIcon } from '../../icons';
import EditSystem from '../../common/Dialogs/EditSystem';
import DeleteSystem from '../../common/Dialogs/DeleteSystem';
import {
  useDeleteSystemMutation,
  useGetSystemByIdQuery,
  useGetSystemsQuery,
  useUpdateSystemLockMutation,
} from '../../redux/services/systems/api';
import useSnackbar from '../../hooks/useSnackbar';
import { IAppState } from '../../typescript/interfaces/appstate.interface';
import { useAppSelector } from '../../redux/store';
import { getDeletedFav } from '../../utils/getDeletedFav';
import { setQuickAccess } from '../../redux/modules/quickAccess';
import SystemDiagram from './SystemDiagram';
import { setSelectedSystemId } from '../../redux/modules/user';
import { UnauthorizedComponentErrorBoundary } from '../../common/UnauthorizedErrorBoundary';
import {
  useGetPackageTargetsQuery,
  useGetTaskDeploymentsQuery,
  useGetTaskQuery,
} from '../../redux/services/packages/api';
import ConfirmDialog from '../../common/Dialogs/ConfirmDialog';
import { ControlPackageState } from '../../redux/services/packages/types';

interface Props {
  className?: string;
  actions: string[];
  id: string;
  backRoute?: any;
  isProjectSystem?: boolean;
  setDeleted?: Dispatch<SetStateAction<string>>;
}

const Root = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  height: '100%',
  minHeight: '200px',
  width: '100%',
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxSizing: 'border-box',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
  '& .diagram': {
    flexGrow: 1,
    '& .point': {
      opacity: 0,
    },
    '& .react-flow__node .edit-btn': {
      display: 'none',
    },
  },
}));

const Head = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  boxSizing: 'border-box',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
  },
}));

const Actions = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  '& > *': {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  color: theme.palette.primary.text,
  fontWeight: 600,
}));

const ConfigButton = ({
  history,
  id,
  projectId,
  disabled,
}: {
  history: History;
  id: string;
  projectId: string;
  disabled?: boolean;
}) => (
  <Button
    component={Link}
    to={{
      pathname: `${ROUTES_PATH.CONFIG}/${id}`,
      state: {
        lastPath: history.location,
        systemId: id,
        projectId,
      },
    }}
    sx={{ mr: 2 }}
    variant="outlined"
    id="System-Config-button"
    disabled={disabled}
  >
    Config
  </Button>
);
ConfigButton.defaultProps = {
  disabled: false,
};

function SystemListDetail({
  className,
  actions,
  id,
  backRoute,
  isProjectSystem,
  setDeleted,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [edit, setEdit] = useState(false);
  const [warn, setWarn] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [openUnlockConfirm, setOpenUnlockConfirm] = useState(false);

  const { showForbiddenError, showSuccess } = useSnackbar();
  const dispatch = useDispatch();

  const history = useHistory();

  const quickAccess = useAppSelector((state: IAppState) => state.quickAccess);
  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );
  const selectedSystemId = useAppSelector(
    (state: IAppState) => state.user.selectedSystemId,
  );

  const [
    updateLock,
    { isSuccess: isLockUpdateSuccess, error: lockUpdateError },
  ] = useUpdateSystemLockMutation();

  useEffect(() => {
    if (isLockUpdateSuccess) showSuccess('System lock updated succesfully');
  }, [isLockUpdateSuccess]);

  useEffect(() => {
    showForbiddenError({
      error: lockUpdateError,
      customForbiddenMessage:
        "You don't have enough permissions to update system lock",
      customDefaultMessage: "System lock wasn't updated",
    });
  }, [lockUpdateError]);

  const { data: systems } = useGetSystemsQuery(undefined, {
    pollingInterval: isDeleted ? 1000 : 0,
  });
  const { data: system } = useGetSystemsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      data: data?.find((s) => s.id === id),
    }),
  });
  const {
    data: systemData,
    isFetching: isLoadingSystem,
    error: systemError,
  } = useGetSystemByIdQuery(id, {
    skip: !id || `${id}` === '0',
  });

  const { data: tasksDeployment } = useGetTaskDeploymentsQuery({ target: id });

  const latestTask = useMemo(() => {
    if (tasksDeployment) {
      return [...tasksDeployment]
        ?.sort((a, b) => {
          const aDate = new Date(a?.modifiedAt);
          const bDate = new Date(b?.modifiedAt);
          if (aDate < bDate) return -1;
          if (aDate > bDate) return 1;
          return 0;
        })
        .find(({ state }) => state === ControlPackageState.RUNNING);
    }
    return undefined;
  }, [tasksDeployment]);

  const { data: task } = useGetTaskQuery(
    { taskId: latestTask?.task },
    { skip: !latestTask?.task },
  );
  const { data: packagesTargets } = useGetPackageTargetsQuery(task?.package, {
    skip: !task?.package,
  });

  const [deleteSystem, { error: deleteSystemError, isSuccess }] =
    useDeleteSystemMutation();

  let projectId = history.location.pathname.replace(
    `${ROUTES_PATH.PROJECT_DETAIL}/`,
    '',
  );

  useEffect(() => {
    setIsDeleted(false);
  }, [JSON.stringify(systems)]);

  if (projectId === `${ROUTES_PATH.SYSTEMS}/list`) projectId = undefined;

  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const isAction = Boolean(actionAnchorEl);
  const handleActionOpen = (event) => {
    event.preventDefault();
    setActionAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleActionClose = (event) => {
    setActionAnchorEl(null);
    event.stopPropagation();
  };

  const quickAccessId = quickAccess.map(
    (link) =>
      link.split('/').filter((entinity) => entinity.startsWith('SYST'))[0],
  );

  const deletedFav = useMemo(
    () => getDeletedFav(quickAccess, id),
    [quickAccess, id],
  );

  const handleDelete = () => {
    if (quickAccessId.find((entId) => entId === id)) {
      dispatch(setQuickAccess(deletedFav));
    }
    deleteSystem(id);
  };

  const handleOpenEdit = () => {
    setEdit(true);
    setActionAnchorEl(null);
  };

  const handleCloseEdit = () => {
    setEdit(false);
  };

  const handleOpenWarn = () => {
    setWarn(true);
    setActionAnchorEl(null);
  };

  const handleCloseWarn = () => {
    setWarn(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setWarn(false);
      setDeleted(system.id);
      dispatch(setSelectedSystemId(undefined));
      showSuccess(`${system.name} system has been successfully deleted`);
    }
  }, [isSuccess]);

  useEffect(() => {
    showForbiddenError({
      error: deleteSystemError,
      customForbiddenMessage:
        "You don't have enough permissions to delete this system",
      customDefaultMessage: "System hasn't been deleted. Something went wrong",
    });
  }, [deleteSystemError]);

  useEffect(() => {
    if (!selectedSystemId && isSuccess) {
      if (!isProjectSystem) history.push(`${ROUTES_PATH.SYSTEMS}/list`);
    }
  }, [selectedSystemId, isSuccess]);

  const handleLockUpdate = () => {
    updateLock({ id: system.id, lock: !system.locked });
    setOpenUnlockConfirm(false);
  };

  return (
    <Root className={className}>
      <Head>
        <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
          {backRoute && (
            <IconButton
              sx={{ ml: 0, mr: 1 }}
              className="outlined"
              onClick={() => {
                backRoute();
              }}
            >
              <KeyboardArrowLeftRounded />
            </IconButton>
          )}
          {system?.locked && (
            <Tooltip title="Unlock system">
              <IconButton
                sx={{ ml: -1 }}
                onClick={() => setOpenUnlockConfirm(true)}
              >
                <LockOutlined />
              </IconButton>
            </Tooltip>
          )}
          <Title id="SystemDiagram-Title" variant="subtitle1" noWrap>
            {system?.name || 'Loading...'}
          </Title>
        </Box>
        <Actions>
          {isProjectSystem && !isEdgeEnv && (
            <ConfigButton
              history={history}
              id={id}
              projectId={projectId}
              disabled={isLoadingSystem || !!systemError}
            />
          )}
          {actions.map((action) => (
            <Fragment key={action}>
              {action === 'open' && (
                <Button
                  variant="contained"
                  component={Link}
                  to={{
                    pathname: `${ROUTES_PATH.SYSTEM_DETAIL}/${id}`,
                    state: {
                      lastPath: history.location,
                    },
                  }}
                  sx={{
                    ml: 0,
                  }}
                  id="System-Open-button"
                  disabled={isLoadingSystem || !!systemError}
                >
                  {isMobile ? 'Open' : 'Open system'}
                </Button>
              )}
              {action === 'duplicate' && (
                <Button variant="contained">Duplicate</Button>
              )}
              {action === 'more' && !isEdgeEnv && (
                <IconButton
                  className={
                    actionAnchorEl &&
                    actionAnchorEl.id === `action-button1-${id}`
                      ? 'outlined active'
                      : 'outlined'
                  }
                  sx={{ mr: 0, ml: 2 }}
                  onClick={handleActionOpen}
                  edge="end"
                  id={`action-button1-${id}`}
                  aria-controls={`action-menu1-${id}`}
                  aria-haspopup="true"
                  aria-expanded={isAction ? 'true' : undefined}
                  aria-label="Menu"
                >
                  <MoreVertRounded />
                </IconButton>
              )}
            </Fragment>
          ))}
        </Actions>
      </Head>
      <Menu
        hidden={
          actionAnchorEl === null ||
          (actionAnchorEl && actionAnchorEl.id !== `action-button1-${id}`)
        }
        id={`action-menu1-${id}`}
        anchorEl={actionAnchorEl}
        open={isAction}
        onClose={handleActionClose}
        MenuListProps={{
          'aria-labelledby': `action-button1-${id}`,
        }}
      >
        <MenuItem onClick={handleOpenEdit} disabled={system?.locked}>
          <ListItemIcon>
            <EditOutlined />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem
          onClick={handleOpenWarn}
          sx={{ color: 'error.main' }}
          disabled={system?.locked}
        >
          <ListItemIcon>
            <TrashIcon />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
      <UnauthorizedComponentErrorBoundary isError={!!systemError}>
        <SystemDiagram
          onFullscreenEnter={() => []}
          onFullscreenExit={() => []}
          isEditable={false}
          className="diagram"
          system={id}
          button={undefined}
          componentsCustomUI={packagesTargets?.components}
        />
      </UnauthorizedComponentErrorBoundary>
      <DeleteSystem
        systemName={system?.name}
        open={warn}
        onClose={handleCloseWarn}
        handleDelete={handleDelete}
        id={id}
      />
      <UnauthorizedComponentErrorBoundary isError={!!systemError}>
        <EditSystem isOpened={edit} closeModal={handleCloseEdit} id={id} />
      </UnauthorizedComponentErrorBoundary>
      <ConfirmDialog
        open={openUnlockConfirm}
        onClose={() => setOpenUnlockConfirm(false)}
        handleAction={handleLockUpdate}
        title={`Unlock ${system?.name}`}
        detail="Unlock system for editing?"
        actionTitle="Unlock system"
      />
    </Root>
  );
}

SystemListDetail.defaultProps = {
  className: '',
  backRoute: undefined,
  isProjectSystem: false,
  setDeleted: () => {},
};

export default memo(SystemListDetail);
