import React from 'react';
import { SettingsOutlined } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useAppSelector } from '../../../redux/store';
import { IAppState } from '../../../typescript/interfaces/appstate.interface';

interface Props {
  handleEditProfileOpen: () => void;
}

const EditProfileButton = (props: Props) => {
  const { handleEditProfileOpen } = props;
  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );

  if (isEdgeEnv) return null;

  return (
    <MenuItem
      id="UserMenu-edit_profile_button"
      sx={{ p: '6px' }}
      onClick={handleEditProfileOpen}
    >
      <ListItemIcon>
        <SettingsOutlined />
      </ListItemIcon>
      Edit profile
    </MenuItem>
  );
};

export default EditProfileButton;
