import React from 'react';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import Breadcrumbs from '../common/Breadcrumbs';
import { Head, HeadLeft } from '../components/project/styles';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
  setBreadcrumb,
  setBreadcrumbsInitialState,
} from '../redux/modules/breadcrumbs/slice';
import { IBreadcrumb } from '../typescript/interfaces/breadcrumbs.interface';

export interface BreadcrumbProps {
  isForMobile: boolean;
  isForDesktop: boolean;
}

export enum TypeOfAction {
  ADD = 'add',
  SET_EMPTY = 'setEmpty',
  UPDATE = 'update',
}

export interface HandleBreadcrumbsProps {
  value: IBreadcrumb[];
  typeOfAction: TypeOfAction;
}

export interface HandleBreadcrumbs {
  handleBreadcrumbs?: (breadcrumbs: HandleBreadcrumbsProps) => void;
}

const withBreadcrumbs = <T,>(
  Component: React.FC<T>,
): React.FC<T & BreadcrumbProps> => {
  const displayName = Component.displayName || Component.name || 'Component';

  const BreadcrumbsWrap = (
    props: T & BreadcrumbProps,
  ): React.ReactElement<T> => {
    const { isForMobile, isForDesktop } = props;
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isEdgeEnv = useAppSelector((state) => state.environment === 'edge');

    const handleBreadcrumbs = ({
      value,
      typeOfAction,
    }: HandleBreadcrumbsProps) => {
      if (typeOfAction === TypeOfAction.ADD) dispatch(setBreadcrumb(value));
      if (typeOfAction === TypeOfAction.SET_EMPTY)
        dispatch(setBreadcrumbsInitialState(value));
    };

    if (isEdgeEnv)
      return <Component {...props} handleBreadcrumbs={handleBreadcrumbs} />;

    return (
      <>
        {isForMobile && (
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Breadcrumbs isMobile={isMobile} />
          </Box>
        )}
        {isForDesktop && (
          <Head sx={{ display: { xs: 'none', md: 'flex' } }}>
            <HeadLeft>
              <Breadcrumbs />
            </HeadLeft>
          </Head>
        )}
        <Component {...props} handleBreadcrumbs={handleBreadcrumbs} />
      </>
    );
  };

  BreadcrumbsWrap.displayName = `withBreadcrumbs(${displayName})`;
  return BreadcrumbsWrap;
};

export default withBreadcrumbs;
