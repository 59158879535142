import React from 'react';
import { DialogContent, DialogContentText } from '@mui/material';
import DialogComponent from './DialogComponent';

interface Props {
  open: boolean;
  onClose: () => void;
  handleAction: () => void;
  isLoadingAction?: boolean;
  isButtonWithLoader?: boolean;
  title: string;
  actionTitle?: string;
  detail: string | React.ReactNode;
}

const ConfirmDialog: React.FC<Props> = (props: Props) => {
  const {
    open,
    onClose,
    handleAction,
    isLoadingAction = false,
    isButtonWithLoader = false,
    title,
    detail,
    actionTitle = '',
  } = props;

  return (
    <DialogComponent
      isOpened={open}
      closeModal={onClose}
      title={title}
      actionTitle={actionTitle || 'Confirm'}
      isLoading={isLoadingAction}
      isButtonWithLoader={isButtonWithLoader}
      handleAction={handleAction}
      cancelId="Confirm-cancel"
      actionId="Confirm-action"
    >
      <DialogContent>
        <DialogContentText>{detail}</DialogContentText>
      </DialogContent>
    </DialogComponent>
  );
};

export default ConfirmDialog;
