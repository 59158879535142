import React, { ReactNode } from 'react';
import {
  DialogActions,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import {
  CancelBtn,
  LoadingBtn,
  Modal,
  ModalHeader,
  RightContent,
  RightContentBlock,
  SaveBtn,
} from './styles';

interface DialogComponentProps {
  isOpened: boolean;
  closeModal: () => void;
  title?: string | ReactNode | React.JSX.Element;
  actionTitle?: string | React.JSX.Element;
  handleAction?: () => void;
  children: ReactNode;
  thirdAction?: ReactNode | React.JSX.Element;
  isCancelButtonDisplayed?: boolean;
  isActionButtonDisabled?: boolean;
  isActionButtonDisplayed?: boolean;
  sx?: any;
  disableFullscreen?: boolean;
  id?: string;
  cancelId?: string;
  actionId?: string;
  cancelButtonTitle?: string | React.JSX.Element;
  forFullscreen?: boolean;
  isButtonWithLoader?: boolean;
  isLoading?: boolean;
  isCloseButtonRendered?: boolean;
  additionalHeaderComponent?: React.JSX.Element;
}

const DialogComponent = (props: DialogComponentProps) => {
  const {
    isOpened,
    closeModal,
    handleAction,
    title,
    actionTitle,
    cancelButtonTitle,
    isCancelButtonDisplayed,
    isActionButtonDisabled,
    isActionButtonDisplayed,
    children,
    disableFullscreen,
    sx,
    id,
    cancelId,
    actionId,
    thirdAction,
    forFullscreen,
    isButtonWithLoader,
    isLoading,
    isCloseButtonRendered,
    additionalHeaderComponent,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Modal
      open={isOpened}
      fullScreen={disableFullscreen ? false : fullScreen}
      onClose={closeModal}
      aria-labelledby="dialog-component"
      aria-describedby="dialog-component-description"
      container={
        forFullscreen ? () => document.querySelector('.fullscreen') : undefined
      }
      sx={sx}
      id={id}
    >
      <ModalHeader>
        {title && (
          <DialogTitle
            id="dialog-component"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1rem 2rem',
            }}
          >
            {title}
          </DialogTitle>
        )}
        <RightContentBlock>
          {additionalHeaderComponent || null}
          {isCloseButtonRendered && (
            <RightContent onClick={closeModal}>
              <CloseRounded />
            </RightContent>
          )}
        </RightContentBlock>
      </ModalHeader>
      {children}
      <DialogActions>
        {thirdAction}
        {isCancelButtonDisplayed &&
          (typeof cancelButtonTitle === 'string' ? (
            <CancelBtn
              className={disableFullscreen && 'fullscreen-disabled'}
              variant="outlined"
              color="inherit"
              onClick={closeModal}
              id={cancelId}
            >
              {cancelButtonTitle}
            </CancelBtn>
          ) : (
            cancelButtonTitle
          ))}
        {isActionButtonDisplayed && !isButtonWithLoader && (
          <SaveBtn
            variant="contained"
            onClick={handleAction}
            disabled={isActionButtonDisabled}
            className={disableFullscreen && 'fullscreen-disabled'}
            id={actionId}
          >
            {actionTitle}
          </SaveBtn>
        )}
        {isActionButtonDisplayed && isButtonWithLoader && (
          <LoadingBtn
            loadingPosition="start"
            disabled={isActionButtonDisabled}
            variant="contained"
            onClick={handleAction}
            className={disableFullscreen && 'fullscreen-disabled'}
            id={actionId}
            loading={isLoading}
          >
            {actionTitle}
          </LoadingBtn>
        )}
      </DialogActions>
    </Modal>
  );
};

DialogComponent.defaultProps = {
  isCancelButtonDisplayed: true,
  isActionButtonDisabled: false,
  isActionButtonDisplayed: true,
  disableFullscreen: false,
  sx: {},
  actionTitle: undefined,
  cancelButtonTitle: 'Cancel',
  id: '',
  cancelId: '',
  actionId: '',
  handleAction: () => null,
  title: undefined,
  thirdAction: undefined,
  forFullscreen: false,
  isButtonWithLoader: false,
  isLoading: false,
  isCloseButtonRendered: false,
  additionalHeaderComponent: undefined,
};

export default React.memo(DialogComponent);
