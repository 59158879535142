import { handleActions } from 'redux-actions';
import {
  setActiveDashboard,
  setControlSubscriptionStatusesLoaded,
  setMachineSubscriptionStatusesLoaded,
  setToggleAccessible,
  setTrendSelectorState,
  setTrendViewState,
} from './actions';
import { environment, envVars } from '../../../env';

const initialState = {
  isMap: environment() === 'cloud',
  isToggleAccessible: envVars.demoMode,
  machineStatusLoaded: false,
  controlStatusLoaded: false,
  linkView: undefined,
};

const viewReducer = handleActions(
  {
    [setActiveDashboard]: (state, { payload }) => ({
      ...state,
      isMap: payload,
    }),
    [setToggleAccessible]: (state, { payload }) => ({
      ...state,
      isToggleAccessible: payload,
    }),
    [setMachineSubscriptionStatusesLoaded]: (state, { payload }) => ({
      ...state,
      machineStatusLoaded: payload,
    }),
    [setControlSubscriptionStatusesLoaded]: (state, { payload }) => ({
      ...state,
      controlStatusLoaded: payload,
    }),
    [setTrendViewState]: (state, { payload }) => {
      const systemID = Object.keys(payload)[0];
      return {
        ...state,
        linkView: {
          ...state?.linkView,
          [systemID]: {
            ...state?.linkView?.[systemID],
            ...payload[systemID],
          },
        },
      };
    },
    [setTrendSelectorState]: (state, { payload }) => {
      const { systemId, panelName, ids } = payload;
      const newState = {
        ...state,
        linkView: {
          ...state?.linkView,
          [systemId]: {
            ...state?.linkView?.[systemId],
            sensors: {
              ...state?.linkView?.[systemId]?.sensors,
              [panelName]: ids,
            },
          },
        },
      };

      return newState;
    },
  },
  initialState,
);

export default viewReducer;
