import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';

const BASE_URL = `${apiBaseUrlV1('stream/v1')}/history`;
const ATTRIBUTES_URL = `${apiBaseUrlV1('structure/v1')}/attributes`;
const CONTROL_ATTRIBUTES_URL = `${apiBaseUrlV1('control/v1')}/attributes`;

interface IAttributeQueryParams {
  id?: string;
  parentAggregate?: string;
}

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAttributes: builder.query<any, IAttributeQueryParams>({
      query: ({ parentAggregate }) =>
        `${ATTRIBUTES_URL}?parentAggregate=${parentAggregate}`,
    }),
    getAttributeById: builder.query<any, IAttributeQueryParams>({
      query: ({ id, parentAggregate }) => {
        const idLink = `/${id}`;
        return `${ATTRIBUTES_URL}${id ? idLink : ''}${
          parentAggregate ? `?parentAggregate=${parentAggregate}` : ''
        }`;
      },
      providesTags: ['Attribute'],
    }),
    getAttributeByAggregate: builder.query<any, string>({
      query: (id) => `${ATTRIBUTES_URL}?rootAggregate=${id.toUpperCase()}`,
      providesTags: ['AttributesAggr'],
    }),
    getControlAttributes: builder.query<any, void>({
      query: () => CONTROL_ATTRIBUTES_URL,
    }),
    attributes: builder.query({
      query: (data: any) => {
        const { id, start, startOp, stop, stopOp } = data;
        const stopLink = `&stop_op=${stopOp}`;
        return {
          url: `${BASE_URL}/attributes/states${
            id &&
            `?${id
              .map((i) => {
                const idLink = `id=${i}`;
                return idLink;
              })
              .join('&')}`
          }${start ? `&start=${start}` : ''}${stop ? `&stop=${stop}` : ''}${
            startOp ? `&start_op=${startOp}` : ''
          }${stopOp ? stopLink : ''}`,
        };
      },
    }),
    attributesWithSelection: builder.query({
      query: (data: any) => {
        const { id, start, startOp, stop, stopOp } = data;
        const stopLink = `&stop_op=${stopOp}`;
        return {
          url: `${BASE_URL}/attributes/fields${
            id &&
            `?${id
              .map((i) => {
                const fieldLink = `field=${i}`;
                return fieldLink;
              })
              .join('&')}:pv,tare`
          }${start ? `&start=${start}` : ''}${stop ? `&stop=${stop}` : ''}${
            startOp ? `&start_op=${startOp}` : ''
          }${stopOp ? stopLink : ''}`,
        };
      },
    }),
    attributesV1: builder.query({
      query: (data: any) => {
        const { id, start, selector, startOp, stop, stopOp } = data;
        const stopLink = `&stop_op=${stopOp}`;
        return {
          url: `${BASE_URL}/attributes/tables/json${
            id &&
            `?${id
              .map((i) => {
                const link = `field=${i}:${selector}`;
                return link;
              })
              .join('&')}`
          }${start ? `&start=${start}` : ''}${stop ? `&stop=${stop}` : ''}${
            startOp ? `&start_op=${startOp}` : ''
          }${stopOp ? stopLink : ''}`,
        };
      },
    }),
  }),
});

export const {
  useGetAttributesQuery,
  useGetAttributeByIdQuery,
  useGetAttributeByAggregateQuery,
  useGetControlAttributesQuery,
  useAttributesQuery,
  useAttributesWithSelectionQuery,
  useAttributesV1Query,
} = extendedApi;
