import React, { useMemo } from 'react';
import cn from 'classnames';
import { DetailWrap, Status } from './StatusStyles';
import { useGetSystemsQuery } from '../../../redux/services/systems/api';
import { SYSTEMS_STATUSES } from '../../../constants';

const defaultProps = {
  showFull: false,
  fullscreen: false,
  style: undefined,
};

const SystemControlStatusTextSubscription = ({
  systemID,
  style,
  showFull,
  fullscreen,
}: {
  systemID: string;
  style?: any;
  showFull?: boolean;
  fullscreen?: boolean;
}) => {
  const { data: systemData } = useGetSystemsQuery(undefined, {
    selectFromResult: (s) => ({
      data: s?.data?.find(({ id }) => id === systemID),
      error: s.error,
    }),
  });

  const controlState = useMemo(() => {
    if (systemData?.state === 0 || systemData?.display === 'Idle')
      return 'Idle';
    if (systemData?.state !== undefined && systemData?.state !== 0)
      return `Running - ${systemData?.display}`;
    return undefined;
  }, [JSON.stringify(systemData)]);

  const title = useMemo(() => {
    if (controlState) return controlState;
    return 'Unknown';
  }, [controlState]);

  const className = useMemo(
    () =>
      SYSTEMS_STATUSES.map((status) => status.toLowerCase()).indexOf(
        title?.toLowerCase().replace(/ /gm, '_'),
      ) > -1
        ? title.toLowerCase().replace(/ /gm, '_')
        : 'running',
    [title],
  );

  return (
    <>
      {fullscreen ? (
        <DetailWrap>
          <span>{showFull ? title : title.slice(0, 22)}</span>
          <Status
            sx={{
              padding: 0,
              width: 8,
              height: 8,
              backgroundColor: 'rgba(112, 112, 112, 0.15)',
            }}
            id={`SystemStatus-Control-${systemID}`}
            className={className}
          />
        </DetailWrap>
      ) : (
        <Status
          className={cn(className, !showFull && 'trimmed')}
          style={style}
          id={`SystemStatus-Control-${systemID}`}
        >
          {title}
        </Status>
      )}
    </>
  );
};

SystemControlStatusTextSubscription.defaultProps = defaultProps;

export default SystemControlStatusTextSubscription;
