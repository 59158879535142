import React, { lazy, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES_PATH } from './constants';
import Loader from './common/Loader';
import SystemPage from './pages/system';
import CreateConfigration from './pages/project-detail/create-config';
import { retry } from './utils/retry';
import Home from './pages/auth/index';
import Map from './components/dashboard/Map';

const ChangePassword = lazy(() =>
  retry(() => import('./pages/auth/ChangePassword')),
);
const ResetPassword = lazy(() =>
  retry(() => import('./pages/auth/ResetPassword')),
);
const LoginWithTemporaryPassword = lazy(() =>
  retry(() => import('./pages/auth/LoginWithTemporaryPassword')),
);
const Relogin = lazy(() => retry(() => import('./pages/auth/Relogin')));
const Revalidate = lazy(() => retry(() => import('./pages/auth/Revalidate')));
const TokenRenewed = lazy(() =>
  retry(() => import('./pages/auth/TokenRenewed')),
);
const Dashboard = lazy(() => retry(() => import('./pages/dashboard')));
const ProjectPage = lazy(() => retry(() => import('./pages/project')));
const ProjectDetailPage = lazy(() =>
  retry(() => import('./pages/project-detail')),
);
const ExtendedProject = lazy(() =>
  retry(() => import('./pages/project-detail/ExtendedProject')),
);

const ManageAppsPage = lazy(() => retry(() => import('./pages/manage-apps')));

const SystemDetailPage = lazy(() =>
  retry(() => import('./pages/system-detail')),
);
const SystemExpandedPage = lazy(() =>
  retry(() => import('./pages/system/system-extended')),
);
const UsersRoles = lazy(() => retry(() => import('./pages/users')));
const Settings = lazy(() => retry(() => import('./pages/settings')));
const ExtendedRole = lazy(() =>
  retry(() => import('./pages/users/ExtendedRole')),
);
const QuickAccess = lazy(() =>
  retry(() => import('./components/dashboard/QuickAccess')),
);
const Systems = lazy(() =>
  retry(() => import('./components/dashboard/Systems')),
);
const Deployments = lazy(() =>
  retry(() => import('./components/dashboard/Tasks')),
);
const MORE = lazy(() => retry(() => import('./pages/more')));

const CreateVisuals = lazy(() =>
  retry(() => import('./pages/project-detail/create-visuals')),
);
const SystemConfig = lazy(() =>
  retry(() => import('./components/system/configs/Config')),
);

const suspendedComponent = (
  Component,
  breadcrumbsProps?: {
    isForDesktop?: boolean;
    isForMobile?: boolean;
  },
) => {
  const { isForDesktop, isForMobile } = breadcrumbsProps || {
    isForDesktop: false,
    isForMobile: false,
  };

  return (
    <Suspense fallback={<Loader />}>
      <Component isForDesktop={isForDesktop} isForMobile={isForMobile} />
    </Suspense>
  );
};

export const dashboardPanes = {
  QUICK_ACCESS: {
    index: 'QUICK_ACCESS',
    hidden: 'mdDown',
    size: {
      xs: 12,
      md: 4,
    },
    component: <QuickAccess />,
  },
  SYSTEMS: {
    index: 'SYSTEMS',
    size: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    component: <Systems />,
  },
  DEPLOYMENTS: {
    index: 'DEPLOYMENTS',
    size: {
      xs: 12,
      sm: 6,
      md: 4,
    },
    component: <Deployments />,
  },
  MAP: {
    index: 'MAP',
    size: {
      xs: 12,
    },
    component: <Map />,
  },
};

export const routes = [
  {
    componentParent: 'Route',
    path: ROUTES_PATH.MAIN,
    component: () => <Home />,
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: ROUTES_PATH.CALLBACK,
    component: Redirect,
    to: ROUTES_PATH.MAIN,
    exact: false,
  },
  {
    componentParent: 'Route',
    path: ROUTES_PATH.RELOGIN,
    component: () => suspendedComponent(Relogin),
    exact: true,
  },
  {
    componentParent: 'Route',
    path: ROUTES_PATH.REVALIDATE,
    component: () => suspendedComponent(Revalidate),
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: ROUTES_PATH.TOKEN_RENEWED,
    component: () => suspendedComponent(TokenRenewed),
    exact: true,
  },
  {
    componentParent: 'Route',
    path: ROUTES_PATH.RESET_PASSWORD,
    component: () => suspendedComponent(ResetPassword),
    exact: true,
  },
  {
    componentParent: 'Route',
    path: ROUTES_PATH.CHANGE_PASSWORD,
    component: () => suspendedComponent(ChangePassword),
    exact: true,
  },
  {
    componentParent: 'Route',
    path: ROUTES_PATH.LOGIN_WITH_TEMPORARY_PASSWORD,
    component: () => suspendedComponent(LoginWithTemporaryPassword),
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: ROUTES_PATH.DASHBOARD,
    component: () => suspendedComponent(Dashboard),
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.PROJECTS}/:projectType/:selectedProject/:selectedPackage`,
    component: () => suspendedComponent(ProjectPage, { isForMobile: true }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.PROJECTS}/:projectType/:selectedProject`,
    component: () => suspendedComponent(ProjectPage, { isForMobile: true }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.PROJECTS}/:projectType`,
    component: () => suspendedComponent(ProjectPage, { isForMobile: true }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.PROJECTS}`,
    component: () => suspendedComponent(ProjectPage, { isForMobile: true }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.PROJECT_DETAIL}/:id`,
    component: () =>
      suspendedComponent(ProjectDetailPage, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.EXTENDED_PROJECT}/:selectedProject/:selectedPackage`,
    component: () =>
      suspendedComponent(ExtendedProject, {
        isForDesktop: false,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.EXTENDED_PROJECT}/:selectedProject`,
    component: () =>
      suspendedComponent(ExtendedProject, {
        isForDesktop: false,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.CREATE_CONFIG}/:id`,
    component: () =>
      suspendedComponent(CreateConfigration, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.CREATE_CONFIG}/`,
    component: () =>
      suspendedComponent(CreateConfigration, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.CREATE_VISUAL}/:id`,
    component: () =>
      suspendedComponent(CreateVisuals, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.CREATE_VISUAL}`,
    component: () =>
      suspendedComponent(CreateVisuals, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.CONFIG}/:id`,
    component: () =>
      suspendedComponent(SystemConfig, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.SYSTEMS}/:state`,
    component: () =>
      suspendedComponent(SystemPage, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.SYSTEM_DETAIL}/:id`,
    component: () =>
      suspendedComponent(SystemDetailPage, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.SYSTEM_EXPANDED}/:id`,
    component: () =>
      suspendedComponent(SystemExpandedPage, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: ROUTES_PATH.USERS,
    component: () => suspendedComponent(UsersRoles),
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: ROUTES_PATH.SETTINGS,
    component: () => suspendedComponent(Settings),
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.EXTENDED_ROLE}/:id`,
    component: () =>
      suspendedComponent(ExtendedRole, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.MORE}/:tab`,
    component: () =>
      suspendedComponent(MORE, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.MANAGE_APPS}`,
    component: () =>
      suspendedComponent(ManageAppsPage, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: true,
  },
  {
    componentParent: 'PrivateRoute',
    path: `${ROUTES_PATH.MANAGE_APPS}/:id`,
    component: () =>
      suspendedComponent(ManageAppsPage, {
        isForDesktop: true,
        isForMobile: true,
      }),
    exact: false,
  },
];
