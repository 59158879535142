import { Box, alpha, styled } from '@mui/material';

export const Status = styled('span')((props) => {
  const { theme } = props;

  return {
    alignItems: 'center',
    border: `1px solid rgba(255, 255, 255, 0.2)`,
    borderRadius: theme.shape.borderRadius,
    color: '#fff',
    display: 'inline-flex',
    textAlign: 'left',
    padding: '2px 6px',
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '0.9rem',
    wordBreak: 'break-all',
    width: 'fit-content',
    '&.trimmed': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '100%',
    },
    '&.running': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.online, &.deployable': {
      backgroundColor: theme.palette.success.main,
    },
    '&.idle': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.offline, &.unknown, &.unpaired, &.cancelled, &.created, &.not_deployable':
      {
        color: theme.palette.text.primary,
        backgroundColor: alpha(theme.palette.offline.main, 0.15),
      },
    '&.warning, &.termination_requested': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.error, &.errored, &.terminated': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.error.main,
    },
    '&.connected': {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
      backgroundColor: alpha(theme.palette.success.main, 0.1),
      border: '1px solid',
    },
    '&.awaiting_ens, &.processing': {
      borderColor:
        theme.palette.mode === 'dark' ? '#D784F8' : theme.palette.primary.main,
      color:
        theme.palette.mode === 'dark' ? '#D784F8' : theme.palette.primary.main,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'transparent'
          : alpha(theme.palette.primary.main, 0.1),
      border: '1px solid',
    },
    '&.pairing': {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    },
    '&.initializing': {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      border: '1px solid',
      borderColor: theme.palette.secondary.main,
    },
  };
});

export const DetailWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: alpha(theme.palette.secondary.main, 0.01),
  borderRadius: 18,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginLeft: theme.spacing(1),
  flexDirection: 'row-reverse',
  maxWidth: '100%',
  minHeight: 36,
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.025),
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: 42,
    borderRadius: 21,
  },
  '& span': {
    [theme.breakpoints.up('lg')]: {
      minHeight: 10,
      fontSize: '1.15rem',
    },
    '&:first-of-type': {
      backgroundColor: 'transparent',
      color: theme.palette.text.secondary,
      textTransform: 'none',
      border: 0,
    },
    '&:last-of-type': {
      marginRight: 4,
      maxHeight: 8,
      [theme.breakpoints.up('lg')]: {
        width: 10,
        height: 10,
        maxHeight: 10,
      },
    },
    '&.running + .dot': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.online + .dot': {
      backgroundColor: theme.palette.success.main,
    },
    '&.idle + .dot': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.offline + .dot': {
      color: theme.palette.text.primary,
      backgroundColor: alpha(theme.palette.offline.main, 0.15),
    },
    '&.warning + .dot': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.error + .dot': {
      backgroundColor: theme.palette.error.main,
    },
    '&.all + .dot': {
      textTransform: 'none',
    },
  },
}));
