import { createApi } from '@reduxjs/toolkit/query/react';
import { wrappedBaseQuery } from './utils';

export const rootApi = createApi({
  reducerPath: 'rootApi',
  refetchOnFocus: true,
  baseQuery: wrappedBaseQuery,
  endpoints: () => ({}),
  tagTypes: [
    'Attribute',
    'AttributesAggr',
    'Users',
    'Roles',
    'RolesWithConstraints',
    'ControlPackage',
    'ControlTaskDeployment',
    'ControlTask',
    'ControlTasks',
    'FrameworkChartsData',
    'Visuals',
    'VisualsByCategory',
    'VisualDocuments',
    'VisualAssets',
    'VisualCategories',
    'EnsProjects',
    'CurrentEnsProject',
    'FnsProjects',
    'FnsPackages',
    'FnsPackageEvents',
    'FnsDeployments',
    'FnsDevEnv',
    'FnsViews',
    'SystemConfigurationTemplates',
    'SystemsExceptions',
    'SystemSpecificSettings',
    'System',
    'Systems',
    'ControlSystem',
    'ControlSystemFeed',
    'ControlSystems',
    'Components',
    'Connectors',
    'Instruments',
    'Instrument',
    'InstrumentsAggr',
    'Ports',
    'ioMaps',
    'ioMap',
    'Sites',
    'DebugSystem',
    'Preferences',
    'SystemSettings',
    'PasswordSettings',
    'CurrentUser',
    'Logo',
  ],
});
